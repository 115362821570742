import React from "react";
import {
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import "./App.css";
import Login from "./App/Auth/Login";
import Main from "./App/Main/Main";
import { Online, Offline } from "react-detect-offline";
import Lottie from "react-lottie";
import offlineTemp from "./Assets/offline.json";
import { LaptopWindowsTwoTone } from "@material-ui/icons";
import ForgotPassword from "./App/Auth/ForgotPassword";
import NewPassword from "./App/Auth/NewPassword";
import { isAuth, authToken } from "./helpers/AuthHelpers";
import Dashboard from "./App/Main/Dashboard";

var time = new Date().getHours();
const _theme = localStorage.getItem("theme")
  ? localStorage.getItem("theme")
  : time > 18
  ? "dark"
  : "light";

function App() {
  const prefersDarkMode = useMediaQuery(
    "(prefers-color-scheme:" + _theme + " )"
  );
  let theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: offlineTemp,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <Online>
        <CssBaseline />
        <BrowserRouter>
          {isAuth ? (
            <Route path="/login">
              <Redirect to="/dashboard" />
            </Route>
          ) : (
            <>
              <Route path="">
                <Redirect to="/login" />
              </Route>
              <Route exact path="/" component={Login} />
              <Route path="/login" component={Login} />
            </>
          )}
          {/* <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} /> */}
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/newpassword" component={NewPassword} />

          <Main />
        </BrowserRouter>
      </Online>
      <Offline>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: window.innerHeight,
          }}
        >
          <Lottie
            options={defaultOptions}
            autoplay
            height={"auto"}
            width={"50%"}
          />
        </div>
      </Offline>
    </ThemeProvider>
  );
}

export default App;
