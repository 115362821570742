import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import "../../App.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../Variables/Constants";
import init from "../../helpers/WindowToken";
import { lastPageRecordCount } from "./Components/PaginationTool";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "../../App.css";
import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

const classes = makeStyles();

export default class Enquiry extends Component {
  state = {
    getAllEmailSign: [],
    allEnquiry: [],
    search: "",
    name: "",
    email: "",
    mobile_no: "",
    enquiry_status: "",
    mobile_OTP: "",

    replied_message: "",

    product_id: "",
    product_name: "",
    product_price: "",
    productDetails: [],
    productEnquiry: {},
    mainimg: "",
    openProductModal: false,

    enquiry_id: "",
    // location: "",
    // min_quantity: "",
    // user_type: "",
    // website: "",
    EnquiryDetails: [],
    EnquiryDetailObj: {},
    openEnquiryDetailModal: false,

    limit: 10,
    page: 1,
    nextPage: null,
    prevPage: null,
    totalDocs: 0,
    totalPages: 1,

    rowId: "",
    visible: false,
    loading: false,
    downloadMessage: "",

    openDeleteModal: false,
    notifyDelete: false,
    delMsg: "",
    delLoading: false,

    notifyEnqury: false,
    enqMsg: "",
    enqLoading: false,

    generate: true,
    downloadfile: "",

    // emailsignature
    regards: "",
    position: "",
    company_name: "",
  };

  componentDidMount() {
    this.getEnquiry();
    // if (init() === "success") {
    //   axios
    //     .get(Constants.getUrls.getAllEmailSignature + `?limit=${3}`)
    //     .then((resp) => {
    //       console.log(resp);
    //       this.setState({
    //         getAllEmailSign: resp.data.data.docs,
    //       });
    //     });
    // }
  }

  getEnquiry = () => {
    //   if ( init() === "success" ){
    axios
      .get(
        Constants.getUrls.getAllEnquiry +
          `?limit=${this.state.limit}` +
          `&page=${this.state.page}`
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            allEnquiry: resp.data.data.docs,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
          },
          () => {
            console.log(
              this.state.allEnquiry,
              this.state.limit,
              this.state.page,
              this.state.nextPage
            );
          }
        );
        this.getCsvPath();
      });
    //   }
  };

  handleSelect = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (init() === "success") {
          axios
            .get(
              Constants.getUrls.getAllEnquiry +
                `?limit=${this.state.limit}` +
                // `&page=${this.state.page}`
                `&page=${1}`
            )
            .then((resp) => {
              console.log(resp);
              this.setState({
                allEnquiry: resp.data.data.docs,
                limit: resp.data.data.limit,
                nextPage: resp.data.data.nextPage,
                page: resp.data.data.page,
                prevPage: resp.data.data.prevPage,
                totalDocs: resp.data.data.totalDocs,
                totalPages: resp.data.data.totalPages,
              });
            });
        }
      }
    );
  };

  handleNext = () => {
    if (init() === "success") {
      axios
        .get(
          Constants.getUrls.getAllEnquiry +
            `?limit=${this.state.limit}` +
            `&page=${this.state.page + 1}`
        )

        .then((resp) => {
          console.log(this.state.allEnquiry, this.state.page);
          this.setState({
            allEnquiry: resp.data.data.docs,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
          });
        });
    }
  };

  handlePrev = () => {
    if (this.state.page > 1) {
      if (init() === "success") {
        axios
          .get(
            Constants.getUrls.getAllEnquiry +
              `?limit=${this.state.limit}` +
              `&page=${this.state.page - 1}`
          )

          .then((resp) => {
            console.log(resp);
            this.setState({
              allEnquiry: resp.data.data.docs,
              limit: resp.data.data.limit,
              nextPage: resp.data.data.nextPage,
              page: resp.data.data.page,
              prevPage: resp.data.data.prevPage,
              totalDocs: resp.data.data.totalDocs,
              totalPages: resp.data.data.totalPages,
            });
          });
      }
    }
  };

  //  search start
  handleChange = (event, value) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.getEnquiry();
      }
    );
  };
  // search ends

  // delete starts
  handleModal = (enquiry) => {
    this.setState({
      rowId: enquiry._id,
      visible: true,
    });
  };

  handleChangeEditor = (e) => {
    if (this.state.replied_message.length >= "") {
      this.setState(
        {
          replied_message: e.target.value.trim(),
        },
        () => {
          console.log(this.state.replied_message);
        }
      );
    }
  };

  handleEnquiry = () => {
    this.setState({
      enqLoading: true,
    });

    let payload = {
      _id: this.state.rowId,
      replied_message: this.state.replied_message,
    };

    if (init() == "success") {
      axios
        .put(Constants.putUrls.ReplyToEnquiry + "/" + this.state.rowId, payload)
        .then((res) => {
          console.log(res);
          this.setState({
            replyToQuiry: true,
            enqLoading: false,
            enqMsg: res.data.message,
            replied_message: "",
          });

          setTimeout(() => {
            this.setState({ replyToQuiry: false, visible: false }, () => {
              this.getEnquiry();
            });
          }, 2000);
        });
    }
  };

  handleModalDetails = (enquiry) => {
    console.log(enquiry.product_id.images);

    this.setState(
      {
        productEnquiry: enquiry,
        mainimg: enquiry.product_id.images[0],
        rowId: enquiry._id,
        openProductModal: true,
      },
      () => {
        // console.log(this.state.productDetails.product_id.images[0]);
        console.log(this.state.productEnquiry.product_id.images[0]);
        // console.log(this.state.productDetails.images);
        // console.log(this.state.productDetails.images[0]);
      }
    );
  };

  handleModalEnquiry = (enquiry) => {
    console.log(enquiry);
    this.setState(
      {
        EnquiryDetailObj: enquiry,
        rowId: enquiry._id,
        openEnquiryDetailModal: true,
      },
      () => {
        console.log(this.state.EnquiryDetailObj);
      }
    );
  };

  handleDeleteModal = (products) => {
    console.log("products");
    this.setState({
      rowId: products._id,
      openDeleteModal: true,
    });
  };
  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
    let payload = {
      _id: this.state.rowId,
    };

    if (init() == "success") {
      axios
        .delete(Constants.postUrls.deleteEnquiry + this.state.rowId)
        .then((res) => {
          console.log(res);
          this.setState({
            deleteENQ: true,
            delLoading: false,
            delMsg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ deleteENQ: false, openDeleteModal: false }, () => {
              this.getEnquiry();
            });
          }, 2000);
        });
    }
  };

  handleSelectPosition = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log(this.state.position);
      }
    );
  };

  // handleSelectRegards = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       console.log(this.state.regards);
  //     }
  //   );
  // };

  handleSelectCompany = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log(this.state.company_name);
      }
    );
  };

  getCsvPath = () => {
    axios.get(Constants.getUrls.getEnquiryExcel).then((resp) => {
      console.log(resp.data.path);
      this.setState({
        downloadfile: resp.data.path,
        generate: true,
      });
    });
    // .catch((error) => {
    //   console.log(error.response);
    //   this.setState({
    //     downloadMessage: error.response.data.message,
    //   });
    // });
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container spcaing={2}>
          <Grid item sm={6}>
            <Typography variant="h4">Enquiry Details</Typography>
          </Grid>
          <Grid container justify="flex-end">
            {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search Contact"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                                />
                        </FormControl> */}

            {/* <Link
                                to="/admin/addcontact"
                                style={{ textDecoration: "none"}}
                                >
                                    <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={()=>{
                                        this.setState({ open: true})
                                    }}
                                    >
                                        Create Contact
                                    </Button>
                                </Link> */}

            <Button color="primary" variant="contained" size="medium">
              {this.state.generate === true ? (
                <a
                  href={`https://core.prglobalresources.com${this.state.downloadfile}`}
                  download
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download Excel
                </a>
              ) : null}
            </Button>
          </Grid>
        </Grid>

        <Grid sm={12} style={{ marginTop: "2rem" }}>
          <Paper elevetion={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.NO</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Enquiry Details</TableCell>
                    {/* <TableCell>Product Name</TableCell> */}
                    <TableCell>Product Details</TableCell>

                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.allEnquiry?.length > 0 ? (
                    this.state.allEnquiry?.map((enquiry, index) => (
                      <TableRow>
                        <TableCell>
                          {" "}
                          {Number((this.state.page - 1) * this.state.limit) +
                            Number(index + 1)}{" "}
                        </TableCell>
                        <TableCell> {enquiry.name} </TableCell>
                        <TableCell> {enquiry.email}</TableCell>
                        <TableCell> {enquiry.mobile_no}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="success"
                            style={{ marginRight: 4, marginBottom: "0px" }}
                            size={"small"}
                            onClick={() => {
                              this.handleModalEnquiry(enquiry);
                            }}
                          >
                            Details
                          </Button>
                        </TableCell>
                        {/* <TableCell>
                          {" "}
                          {enquiry.product_id.product_name}
                        </TableCell> */}
                        <TableCell>
                          <Button
                            variant={"contained"}
                            color="secondary"
                            style={{ marginRight: 4, marginBottom: "0px" }}
                            size={"small"}
                            onClick={() => {
                              this.handleModalDetails(enquiry);
                            }}
                          >
                            Details
                          </Button>
                        </TableCell>

                        <TableCell>
                          {/* <Link
                                                to={{
                                                pathname: "" + enquiry._id,
                                                state: { data: enquiry },
                                                }}
                                                style={{textDecoration:"none"}}
                                             > */}
                          <Button
                            variant={"outlined"}
                            color="default"
                            style={{ marginRight: 4, marginBottom: "0px" }}
                            size={"small"}
                            onClick={() => {
                              this.handleModal(enquiry);
                            }}
                          >
                            Reply
                          </Button>

                          <Button
                            size={"small"}
                            variant={"outlined"}
                            color="secondary"
                            onClick={() => {
                              this.handleDeleteModal(enquiry);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>NO DATA FOUND</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TableRow
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div>
                  <p>
                    Showing{" "}
                    {Number((this.state.page - 1) * this.state.limit) + 1} to{" "}
                    {lastPageRecordCount(
                      this.state.totalDocs,
                      this.state.page,
                      this.state.limit
                    )}{" "}
                    of {this.state.totalDocs} Entries
                  </p>
                </div>

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "200px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-12px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      value={this.state.limit}
                      onChange={this.handleSelect}
                      inputProps={{
                        name: "limit",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Select>
                  </FormControl>
                </div>

                {/* Rows PerPage END */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "290px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mx-5"
                    style={{ margin: "10px" }}
                    disabled={this.state.prevPage === null}
                    onClick={this.handlePrev}
                    // Hidden
                  >
                    Previous
                  </Button>{" "}
                  <Pagination
                    count={this.state.totalPages}
                    shape="rounded"
                    page={this.state.page}
                    onChange={this.handleChange}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className="mx-5"
                    disabled={this.state.nextPage === null}
                    style={{ margin: "10px" }}
                    onClick={this.handleNext}
                  >
                    Next
                  </Button>
                </div>
              </TableRow>

              {/* DELETE MODAL */}
              <Modal
                visible={this.state.openDeleteModal}
                onClickAway={() => this.setState({ openDeleteModal: false })}
                width="450px"
                height="80px"
                effect="fadeInUp"
              >
                <Row>
                  <Col xs={12} xm={12} md={12}>
                    <Card>
                      <CardBody>
                        <div className="modal">
                          <h3
                            classname="changeColor"
                            style={{ color: "black" }}
                          >
                            {" "}
                            Are you sure you want to Delete??
                          </h3>

                          {this.state.deleteENQ ? (
                            <Snackbar
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "bottom",
                              }}
                              open={true}
                              autoHideDuration={5000}
                              color={"s"}
                              message="deleted Successfully"
                            ></Snackbar>
                          ) : null}

                          <Row>
                            <Col xs={12} xm={12} md={4}>
                              <br />
                              <Button
                                className=""
                                color="secondary"
                                variant="contained"
                                style={{ margin: "20px" }}
                                onClick={() => {
                                  this.handleDelete(this.state.rowId);
                                }}
                              >
                                {this.state.deleteENQ
                                  ? "Deleting Enquiry"
                                  : "Yes"}
                              </Button>
                              <Button
                                className=""
                                variant="contained"
                                color="primary"
                                style={{ margin: "20px", marginLeft: "4px" }}
                                onClick={() => {
                                  this.setState({ openDeleteModal: false });
                                }}
                              >
                                no
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Modal>
              {/* REPLY MODAL */}
              <Modal
                visible={this.state.visible}
                onClickAway={() => this.setState({ visible: false })}
                width="800px"
                height="400px"
                effect="fadeInUp"
              >
                <Row>
                  <Col xs={12} xm={12} md={12}>
                    <Card>
                      <CardBody>
                        <div className="modal">
                          <h3
                            classname="changeColor"
                            style={{ color: "black" }}
                          >
                            Reply To Enquiry
                          </h3>
                          {/* <Grid
                            container
                            sm={12}
                            spacing={2}
                            style={{ marginLeft: "5px", marginTop: "0px" }}
                          >
                            <h4 style={{ color: "black" }}> email signature</h4>
                          </Grid> */}

                          {this.state.replyToQuiry ? (
                            <Snackbar
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "bottom",
                              }}
                              open={true}
                              autoHideDuration={3000}
                              color={"s"}
                              message="Mail sent sucessfully"
                            ></Snackbar>
                          ) : null}

                          <Grid
                            container
                            sm={12}
                            spacing={2}
                            style={{ marginLeft: "5px", marginTop: "1rem" }}
                          >
                            {/* <Grid item sm={4}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  style={{
                                    marginLeft: "15px",
                                  }}
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  Select Regards
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.regards}
                                  inputProps={{
                                    name: "regards",
                                    id: "simple-select",
                                    onChange: this.handleSelectRegards,
                                  }}
                                >
                                  <MenuItem value="Good Morning">
                                    Good Morning
                                  </MenuItem>
                                  <MenuItem value="Good Afternoon">
                                    Good Afternoon
                                  </MenuItem>
                                  <MenuItem value="Good Evening">
                                    Good Evening
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid> */}
                            {/* 
                            <Grid item sm={4}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  style={{
                                    marginLeft: "15px",
                                  }}
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  Select Position
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.position}
                                  inputProps={{
                                    name: "position",
                                    id: "simple-select",
                                    onChange: this.handleSelectPosition,
                                  }}
                                >
                                  <MenuItem value="Admin">Admin</MenuItem>
                                  <MenuItem value="MD Director">
                                    MD Director
                                  </MenuItem>
                                  <MenuItem value="CEO">CEO</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid> */}
                            {/* 
                            <Grid item sm={4}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  Select Company
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.company_name}
                                  inputProps={{
                                    name: "company_name",
                                    id: "simple-select",
                                    onChange: this.handleSelectCompany,
                                  }}
                                >
                                  <MenuItem value="PR Paper">PR Paper</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid> */}
                          </Grid>

                          <Grid
                            container
                            sm={12}
                            spacing={2}
                            style={{ marginLeft: "5px", marginTop: "1rem" }}
                          >
                            <Grid item sm={12} xs={6}>
                              <div
                                style={{
                                  color: "black",
                                  marginTop: "0.2rem",
                                  marginLeft: "0.3rem",
                                  marginRight: "0.8rem",
                                }}
                              >
                                {/* <SunEditor
                            // width="120%"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                // ['imageGallery'], // You must add the "imageGalleryUrl".
                                // ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                // ['save', 'template'],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "180px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            name="repiled_message"
                            value={this.state.replied_message}
                            onChange={this.handleChangeEditor.bind(this)}
                          /> */}

                                <textarea
                                  name="repiled_message"
                                  cols={90}
                                  rows={10}
                                  value={this.state.replied_message}
                                  onChange={this.handleChangeEditor}
                                  style={{
                                    // marginLeft: "0.7rem",
                                    // minHeight: "180px",
                                    padding: "0.6rem",
                                    fontSize: "1rem",
                                    fontFamily: "sans-serif",
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>

                          <Row>
                            <Col xs={12} xm={12} md={4}>
                              <br />
                              <Button
                                className="mx-5 MuiButton-contained.Mui-disabled"
                                color="primary"
                                variant="contained"
                                style={{ margin: "5px" }}
                                disabled={this.state.replied_message === ""}
                                onClick={() => {
                                  this.handleEnquiry(this.state.rowId);
                                }}
                              >
                                {this.state.replyToQuiry
                                  ? "Replying...Enquiry"
                                  : "Reply Enquiry"}
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: "5px", marginLeft: "4px" }}
                                onClick={() => {
                                  this.setState({ visible: false });
                                }}
                              >
                                Back
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Modal>
              {/* PRODUCT MODEL */}
              <Modal
                visible={this.state.openProductModal}
                onClickAway={() => this.setState({ openProductModal: false })}
                width="1100px"
                height="600px"
                effect="fadeInUp"
              >
                <Row>
                  <Col xs={12} xm={12} md={12}>
                    <Card>
                      <CardBody>
                        <div className="modalDetails">
                          <h3
                            classname="changeColor"
                            style={{ color: "black", fontSize: "1.8rem" }}
                          >
                            Product Details
                          </h3>

                          <Grid
                            container
                            sm={12}
                            spacing={2}
                            style={{ marginLeft: "5px", paddingTop: "2px" }}
                          >
                            <div className="first-section">
                              <div className="first-section-left">
                                <div className="first-section-left-left"></div>
                                <div className="first-section-left-right">
                                  <img
                                    src={
                                      "https://core.prglobalresources.com" +
                                      this.state.mainimg
                                    }
                                    alt="productImage"
                                  />
                                  <div className="Extra-detail-Container">
                                    <div className="Extra-detail-Container-productType">
                                      <h3>
                                        Name:{" "}
                                        {
                                          this.state.productEnquiry?.product_id
                                            ?.product_name
                                        }
                                      </h3>

                                      <h3>
                                        Type:{" "}
                                        {
                                          this.state.productEnquiry?.product_id
                                            ?.product_type
                                        }
                                      </h3>

                                      <h3>
                                        Quality:{" "}
                                        {
                                          this.state.productEnquiry?.product_id
                                            ?.product_quality
                                        }
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="first-section-right">
                                <div className="first-section-right-title">
                                  <h3>
                                    {
                                      this.state.productEnquiry?.product_id
                                        ?.product_name
                                    }
                                  </h3>
                                </div>

                                <div className="first-section-right-price-star">
                                  <div className="first-section-right-price">
                                    <h3>
                                      ₹
                                      {
                                        this.state.productEnquiry?.product_id
                                          ?.product_price
                                      }
                                    </h3>
                                  </div>
                                  {/* <div className="first-section-right-star">
                                    <h3>
                                      Ratings⭐
                                      {this.state.productEnquiry?.product_avg_rate}
                                    </h3>
                                  </div> */}
                                </div>

                                <div className="first-section-right-details">
                                  <div className="grid-container">
                                    <div className="item">
                                      <h5>Material</h5>
                                    </div>
                                    <div className="item">
                                      <h5>
                                        {this.state.productEnquiry?.product_id
                                          ?.short_details?.material || "NA"}
                                      </h5>
                                    </div>
                                    <div className="item">
                                      <h5>Usage/Application</h5>
                                    </div>
                                    <div className="item">
                                      <h5>
                                        {this.state.productEnquiry?.product_id
                                          ?.short_details?.usage_application ||
                                          "NA"}
                                      </h5>
                                    </div>
                                    <div className="item">
                                      <h5>Roughness</h5>
                                    </div>
                                    <div className="item">
                                      <h5>
                                        {this.state.productEnquiry?.product_id
                                          ?.short_details?.roughness || "NA"}
                                      </h5>
                                    </div>
                                    <div className="item">
                                      <h5>Color</h5>
                                    </div>
                                    <div className="item">
                                      <h5>
                                        {this.state.productEnquiry?.product_id
                                          ?.short_details?.colour || "NA"}
                                      </h5>
                                    </div>
                                    <div className="item">
                                      <h5>Paper Size</h5>
                                    </div>
                                    <div className="item">
                                      {" "}
                                      <h5>
                                        {" "}
                                        {this.state.productEnquiry?.product_id
                                          ?.short_details?.paper_size || "NA"}
                                      </h5>
                                    </div>
                                    <div className="item">
                                      <h5>Product GSM</h5>
                                    </div>
                                    <div className="item">
                                      <h5>
                                        {this.state.productEnquiry?.product_id
                                          ?.short_details?.product_gsm || "NA"}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>

                          <Row>
                            <Col xs={12} xm={12} md={4}>
                              <br />

                              <Button
                                className=""
                                variant="contained"
                                color="secondary"
                                style={{
                                  marginBottom: "10px",
                                  marginLeft: "4px",
                                }}
                                onClick={() => {
                                  this.setState({ openProductModal: false });
                                }}
                              >
                                Back
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Modal>

              {/* Enquiry details modal */}
              <Modal
                visible={this.state.openEnquiryDetailModal}
                onClickAway={() =>
                  this.setState({ openEnquiryDetailModal: false })
                }
                width="600px"
                height="400px"
                effect="fadeInUp"
              >
                <Row>
                  <Col xs={12} xm={12} md={12}>
                    <Card>
                      <CardBody>
                        <div className="modalDetails">
                          <h3
                            classname="changeColor"
                            style={{ color: "black", fontSize: "1.8rem" }}
                          >
                            Enquiry Details
                          </h3>
                          <Grid
                            container
                            sm={12}
                            spacing={2}
                            style={{ marginLeft: "5px", paddingTop: "2px" }}
                          >
                            <div className="enquiry-detail-modal">
                              <div className="enquiry-grid-container">
                                <div className="enq-item">
                                  <h3>Company Name :</h3>
                                </div>

                                <div className="enq-item">
                                  <h3>
                                    {this.state.EnquiryDetailObj.company_name ||
                                      "NA"}
                                  </h3>
                                </div>

                                <div className="enq-item">
                                  <h3>Location :</h3>
                                </div>

                                <div className="enq-item">
                                  <h3>
                                    {this.state.EnquiryDetailObj.location ||
                                      "NA"}
                                  </h3>
                                </div>

                                <div className="enq-item">
                                  <h3>User Type:</h3>
                                </div>

                                <div className="enq-item">
                                  <h3>
                                    {this.state.EnquiryDetailObj.user_type ||
                                      "NA"}
                                  </h3>
                                </div>

                                <div className="enq-item">
                                  <h3>Website :</h3>
                                </div>

                                <div className="enq-item">
                                  <h3>
                                    {this.state.EnquiryDetailObj.website ||
                                      "NA"}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Row>
                            <Col xs={12} xm={12} md={4}>
                              <br />
                              <Button
                                className=""
                                variant="contained"
                                color="secondary"
                                style={{
                                  marginBottom: "10px",
                                  marginLeft: "4px",
                                }}
                                onClick={() => {
                                  this.setState({
                                    openEnquiryDetailModal: false,
                                  });
                                }}
                              >
                                Back
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Modal>
            </TableContainer>
          </Paper>
        </Grid>
      </Container>
    );
  }
}
