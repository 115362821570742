import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
// import FabButtons from "@mui/material/F7FabButtons"
// import FabButton from "@mui/material/FabButton"
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import "../../App.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import EditIcon from "@material-ui/icons/Edit";
import { FaBars, FaTimes } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { FcViewDetails } from "react-icons/fc";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../Variables/Constants";
import init from "../../helpers/WindowToken";
import { lastPageRecordCount } from "./Components/PaginationTool";
import "../../App.css";

export default class products extends Component {
  state = {
    allProducts: [],
    search: "",
    product_name: "",
    category_id: "",
    sub_category_id: "",
    product_type: "",
    product_quality: "",
    product_price: "",
    price_for_min_quantity: "",
    short_details: "",
    detail_description: "",
    images: "",
    tags: "",
    product_avg_rate: "",

    productDetails: [],
    mainimg: "",

    buttonValue: "listView",

    limit: 10,
    page: 1,
    nextPage: null,
    prevPage: null,
    totalDocs: 0,
    totalPages: 1,

    rowId: "",
    visible: false,
    product_id: "",
    product_name: "",
    product_price: "",
    openProductModal: false,
    productDetails: [],
    productsDetail: {},
    mainimg: "",

    openDeleteModal: false,
    loading: false,
    notifyDelete: false,
    delMsg: "",
    delLoading: false,
    click: false,
    currIndex: "",
  };

  componentDidMount() {
    this.getProducts();
  }

  getProducts = () => {
    //   if ( init() === "success" ){
    axios
      .get(
        Constants.getUrls.getAllProducts +
          `?limit=${this.state.limit}` +
          `&page=${this.state.page}`
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            allProducts: resp.data.data.docs,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
            // loading: true
          },
          () => {
            console.log(this.state.allProducts);
          }
        );
      });
    //   }
  };

  handleSelect = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (init() === "success") {
          axios
            .get(
              Constants.getUrls.getAllProducts +
                `?limit=${this.state.limit}` +
                `&page=${1}`
            )
            .then((resp) => {
              console.log(resp);
              this.setState({
                allProducts: resp.data.data.docs,
                limit: resp.data.data.limit,
                nextPage: resp.data.data.nextPage,
                page: resp.data.data.page,
                prevPage: resp.data.data.prevPage,
                totalDocs: resp.data.data.totalDocs,
                totalPages: resp.data.data.totalPages,
              });
            });
        }
      }
    );
  };

  handleNext = () => {
    if (init() === "success") {
      axios
        .get(
          Constants.getUrls.getAllProducts +
            `?limit=${this.state.limit}` +
            `&page=${this.state.page + 1}`
        )

        .then((resp) => {
          console.log(this.state.allProducts, this.state.page);
          this.setState({
            allProducts: resp.data.data.docs,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
          });
        });
    }
  };

  handlePrev = () => {
    if (this.state.page > 1) {
      if (init() === "success") {
        axios
          .get(
            Constants.getUrls.getAllProducts +
              `?limit=${this.state.limit}` +
              `&page=${this.state.page - 1}`
          )

          .then((resp) => {
            console.log(resp);
            this.setState({
              allProducts: resp.data.data.docs,
              limit: resp.data.data.limit,
              nextPage: resp.data.data.nextPage,
              page: resp.data.data.page,
              prevPage: resp.data.data.prevPage,
              totalDocs: resp.data.data.totalDocs,
              totalPages: resp.data.data.totalPages,
            });
          });
      }
    }
  };

  //  search start
  handleChange = (event, value) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.getProducts();
      }
    );
  };
  // search ends

  handleButton = (value) => {
    console.log(value);
    if (value === "tabularView") {
      this.setState({
        buttonValue: value,
      });
    } else {
      this.setState({
        buttonValue: value,
      });
    }
  };

  // handleClick = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // delete starts
  handleDeleteModal = (products) => {
    console.log("products");
    this.setState({
      rowId: products._id,
      openDeleteModal: true,
    });
  };

  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
    let payload = {
      _id: this.state.rowId,
    };

    if (init() == "success") {
      // this.setState({ delLoading: true });
      axios
        .delete(Constants.postUrls.deleteProducts + this.state.rowId)
        .then((res) => {
          console.log(res);
          this.setState({
            deleteProduct: true,
            delLoading: false,
            delMsg: res.data.message,
          });
          setTimeout(() => {
            this.setState(
              { deleteProduct: false, openDeleteModal: false },
              () => {
                this.getProducts();
              }
            );
          }, 2000);
        });
    }
  };

  handleModalDetails = (products) => {
    console.log(products);

    this.setState(
      {
        productsDetail: products,
        mainimg: products.images[0],
        rowId: products._id,
        openProductModal: true,
      },
      () => {
        console.log(this.state.productsDetail);
        // console.log(this.state.productDetails.images);
        // console.log(this.state.productDetails.images[0]);
      }
    );
  };

  handleClick = (x) => {
    this.setState({
      click: !this.state.click,
      currIndex: x,
    });
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container spcaing={2}>
          <Grid item sm={6}>
            <Typography variant="h4">Our Products</Typography>
          </Grid>
          <Grid container justify="flex-end">
            {/* <FormControl >
                          <TextField
                              id="standard-basic"
                              label="Search Testimonial"
                              variant="standard"
                              size="small"
                              name="search"
                              onChange={this.handleChange}
                              />
                      </FormControl> */}
            {this.state.buttonValue === "tabularView" && (
              <Button
                style={{
                  textDecoration: "none",
                  marginBottom: "3rem",
                  marginRight: "1rem",
                }}
                color="secondary"
                variant="contained"
                size="medium"
                onClick={() => this.handleButton("listView")}
                value="listView"
              >
                List View
              </Button>
            )}
            {this.state.buttonValue === "listView" && (
              <Button
                style={{
                  textDecoration: "none",
                  marginBottom: "3rem",
                  marginRight: "1rem",
                }}
                color="secondary"
                variant="contained"
                size="medium"
                onClick={() => this.handleButton("tabularView")}
                value="tabularView"
              >
                Tabular View
              </Button>
            )}
            <Link
              to="/admin/addproducts"
              style={{ textDecoration: "none", marginBottom: "3rem" }}
            >
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                Add Products
              </Button>
            </Link>
          </Grid>
        </Grid>

        {this.state.buttonValue === "listView" ? (
          <div className="Product-container">
            {this.state.allProducts?.length > 0 ? (
              this.state.allProducts.map((products, index) => (
                <div className="Product-card">
                  <div
                    className="icons-Wrapper"
                    onClick={() => {
                      this.handleClick(index);
                    }}
                  >
                    {this.state.click && this.state.currIndex == index ? (
                      <FaTimes
                        className="icon"
                        size={20}
                        style={{ color: "grey" }}
                      />
                    ) : (
                      <FaBars
                        className="icon"
                        size={20}
                        style={{ color: "grey" }}
                      />
                    )}
                    {this.state.click && this.state.currIndex === index && (
                      <div>
                        {/* <MdDelete
                          className="delete"
                          size={25}
                          style={{ color: "red" }}
                          onClick={() => {
                            this.handleDeleteModal(products);
                          }}
                        /> */}
                        <Tooltip title="Delete Card" placement="top">
                          <DeleteIcon
                            className="delete"
                            size={25}
                            style={{ color: "red" }}
                            onClick={() => {
                              this.handleDeleteModal(products);
                            }}
                          />
                        </Tooltip>

                        <Link
                          to={{
                            pathname: `/admin/updateproducts/${products._id}?view=${this.state.buttonValue}`,
                            state: { data: products },
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Tooltip title="Update Section" placement="top">
                            <EditIcon
                              className="edit"
                              size={20}
                              style={{
                                borderRadius: "3px",
                              }}
                            />
                          </Tooltip>
                        </Link>

                        {/* <FcViewDetails
                          className="detail"
                          size={25}
                          style={{ color: "blue" }}
                          onClick={() => {
                            this.handleModalDetails(products);
                          }}
                        /> */}
                        <Tooltip title="Details Section" placement="top">
                          <ListAltIcon
                            className="detail"
                            size={25}
                            style={{ color: "black" }}
                            onClick={() => {
                              this.handleModalDetails(products);
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}

                    {/* <FaTimes className="icon" size={20}  style={{color : "black"}}/> */}
                  </div>

                  {/* <div className="drop-icon">
                    <MdDelete className="del-icon" size={20}/>
                    <FiEdit className="edit-icon" size={20} style={{color : "blue"}}/>
                    <FcViewDetails className="detail-icon" size={20} style={{color : "grey"}}/>  
                  </div> */}

                  <div className="card-img" style={{
                     height:"200px",
                     width:"275px"
                  }}>
                    <img
                      src={
                        Constants.imgDomain.imgMainDomain +
                        `${products?.images[0]}`
                      }
                      alt="productImg"
                      height="200vh"
                      width="50vh"
                    />
                  </div>

                  <div className="product-title">
                    <h3>{products?.product_name}</h3>
                  </div>

                  <div className="Price-details">
                    <h3>{"₹" + products.product_price}</h3>
                  </div>

                  {/* <div className="card-rating">
                    <h3>⭐Ratings {products.product_avg_rate}</h3>
                  </div> */}
                </div>
              ))
            ) : (
              <div>No Product Card Found</div>
            )}
          </div>
        ) : (
          <Grid sm={12}>
            <Paper elevetion={1}>
              <TableContainer>
                <Table size="small" stickyHeader aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Product Type</TableCell>
                      <TableCell>Product Quality</TableCell>
                      <TableCell>Product Price</TableCell>
                      {/* <TableCell>Price for Minimum Quantity</TableCell> */}
                      <TableCell>Colour</TableCell>
                      <TableCell>Shape</TableCell>
                      <TableCell>Materials</TableCell>
                      <TableCell>Usage Application</TableCell>
                      <TableCell>Roughness</TableCell>
                      <TableCell>Paper Size</TableCell>
                      <TableCell>Product GSM</TableCell>
                      <TableCell>Tags</TableCell>
                      {/* <TableCell>Ratings</TableCell> */}
                      {/* <TableCell>Details Description</TableCell> */}
                      <TableCell>Images</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.allProducts?.length > 0 ? (
                      this.state.allProducts.map((products, index) => (
                        <TableRow>
                          <TableCell>
                            {" "}
                            {Number((this.state.page - 1) * this.state.limit) +
                              Number(index + 1)}{" "}
                          </TableCell>
                          <TableCell> {products.product_name} </TableCell>
                          <TableCell> {products.product_type}</TableCell>
                          <TableCell> {products.product_quality}</TableCell>
                          <TableCell> {products.product_price}</TableCell>
                          {/* <TableCell>
                            {" "}
                            {products.price_for_min_quantity}
                          </TableCell> */}
                          <TableCell>
                            {" "}
                            {products.short_details.colour || "NA"}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {products.short_details.shape || "NA"}
                          </TableCell>
                          <TableCell>
                            {products.short_details.material || "NA"}
                          </TableCell>

                          <TableCell>
                            {products.short_details.usage_application || "NA"}
                          </TableCell>

                          <TableCell align="center">
                            {products.short_details.roughness || "NA"}
                          </TableCell>

                          <TableCell align="center">
                            {products.short_details.paper_size || "NA"}
                          </TableCell>

                          <TableCell>
                            {products.short_details.product_gsm || "NA"}
                          </TableCell>

                          <TableCell> {products.tags}</TableCell>
                          {/* <TableCell align="center">
                            {" "}
                            {products.product_avg_rate}
                          </TableCell> */}
                          {/* <TableCell> {products.detail_description}</TableCell> */}

                          {/* <TableCell>
                            {" "}
                            {products.images.map((img) => (
                              <img
                                src={
                                  Constants.imgDomain.imgMainDomain + `${img}`
                                }
                                alt="product_img"
                                width="50vw"
                                height="50vh"
                              />
                            ))}
                          </TableCell> */}

                          <TableCell>
                            <img
                              src={
                                Constants.imgDomain.imgMainDomain +
                                `${products?.images[0]}`
                              }
                              width="100vw"
                              height="100vh"
                              style={{ borderRadius: "5px" }}
                              alt="productImg"
                            />
                          </TableCell>

                          <TableCell align="center">
                            <Link
                              to={{
                                pathname: `/admin/updateproducts/${products._id}?view=${this.state.buttonValue}`,
                                // pathname: "/admin/updateproducts/",
                                state: { data: products },
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                variant={"outlined"}
                                color="default"
                                style={{ marginRight: 4, marginBottom: 8 }}
                                size={"small"}
                              >
                                Update
                              </Button>
                            </Link>
                            <Button
                              size={"small"}
                              variant={"outlined"}
                              color="secondary"
                              onClick={() => {
                                this.handleDeleteModal(products);
                              }}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>NO DATA FOUND</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        )}

        <TableRow style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <p>
              Showing {Number((this.state.page - 1) * this.state.limit) + 1} to{" "}
              {lastPageRecordCount(
                this.state.totalDocs,
                this.state.page,
                this.state.limit
              )}{" "}
              of {this.state.totalDocs} Entries
            </p>
          </div>

          {/* Rows PerPage START */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "200px",
            }}
          >
            <p>Rows Per Page</p>

            <FormControl
              className="selectMerchants"
              style={{ marginRight: "20px", marginTop: "-12px" }}
            >
              <InputLabel htmlFor="age-native-simple"></InputLabel>
              <Select
                native
                value={this.state.limit}
                onChange={this.handleSelect}
                inputProps={{
                  name: "limit",
                  id: "age-native-simple",
                }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Select>
            </FormControl>
          </div>

          {/* Rows PerPage END */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "290px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              className="mx-5"
              style={{ margin: "10px" }}
              disabled={this.state.prevPage === null}
              onClick={this.handlePrev}
              // Hidden
            >
              Previous
            </Button>{" "}
            <Pagination
              count={this.state.totalPages}
              shape="rounded"
              page={this.state.page}
              onChange={this.handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              className="mx-5"
              disabled={this.state.nextPage === null}
              style={{ margin: "10px" }}
              onClick={this.handleNext}
            >
              Next
            </Button>
          </div>
        </TableRow>

        <Modal
          visible={this.state.openDeleteModal}
          onClickAway={() => this.setState({ openDeleteModal: false })}
          width="450px"
          height="80px"
          effect="fadeInUp"
        >
          <Row>
            <Col xs={12} xm={12} md={12}>
              <Card>
                <CardBody>
                  <div className="modal">
                    <h3 classname="changeColor" style={{ color: "black" }}>
                      {" "}
                      Are you sure you want to Delete??
                    </h3>

                    {this.state.deleteProduct ? (
                      <Snackbar
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "bottom",
                        }}
                        open={true}
                        autoHideDuration={5000}
                        color={"s"}
                        message="deleted Successfully"
                      ></Snackbar>
                    ) : null}

                    <Row>
                      <Col xs={12} xm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="secondary"
                          variant="contained"
                          style={{ margin: "20px" }}
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.deleteProduct
                            ? "Deleting Products"
                            : "Yes"}
                        </Button>
                        <Button
                          className=""
                          variant="contained"
                          color="primary"
                          style={{ margin: "20px", marginLeft: "4px" }}
                          onClick={() => {
                            this.setState({ openDeleteModal: false });
                          }}
                        >
                          no
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal>
        {/* product detail modal */}
        <Modal
          visible={this.state.openProductModal}
          onClickAway={() => this.setState({ openProductModal: false })}
          width="1100px"
          height="600px"
          effect="fadeInUp"
        >
          <Row>
            <Col xs={12} xm={12} md={12}>
              <Card>
                <CardBody>
                  <div className="modalDetails">
                    <h3
                      classname="changeColor"
                      style={{ color: "black", fontSize: "1.8rem" }}
                    >
                      Product Details
                    </h3>

                    <Grid
                      container
                      sm={12}
                      spacing={2}
                      style={{ marginLeft: "5px", paddingTop: "10px" }}
                    >
                      <div className="first-section">
                        <div className="first-section-left">
                          <div className="first-section-left-left"></div>
                          <div className="first-section-left-right">
                            <img
                              src={
                                "https://core.prglobalresources.com" +
                                this.state.mainimg
                              }
                              style={{ borderRadius: "15px" }}
                              alt="productImage"
                            />
                            <div className="Extra-detail-Container">
                              <div className="Extra-detail-Container-productType">
                                <h3>
                                  <span>Name: </span>
                                  {this.state.productsDetail?.product_name ||
                                    "NA"}
                                </h3>

                                <h3>
                                  <span>Type: </span>
                                  {this.state.productsDetail?.product_type ||
                                    "NA"}
                                </h3>

                                <h3>
                                  <span>Quality: </span>
                                  {this.state.productsDetail?.product_quality ||
                                    "NA"}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="first-section-right">
                          <div className="first-section-right-title">
                            <h3>{this.state.productsDetail?.product_name}</h3>
                          </div>

                          <div className="first-section-right-price-star">
                            <div className="first-section-right-price">
                              <h3>
                                ₹{this.state.productsDetail?.product_price}
                              </h3>
                            </div>
                            {/* <div className="first-section-right-star">
                              <h3>
                                Ratings⭐
                                {this.state.productDetails?.product_avg_rate}
                              </h3>
                            </div> */}
                          </div>

                          <div className="first-section-right-details">
                            <div className="grid-container">
                              <div className="item">
                                <h5>Material</h5>
                              </div>
                              <div className="item">
                                <h5>
                                  {this.state.productsDetail?.short_details
                                    ?.material || "NA"}
                                </h5>
                              </div>
                              <div className="item">
                                <h5>Usage/Application</h5>
                              </div>
                              <div className="item">
                                <h5>
                                  {this.state.productsDetail?.short_details
                                    ?.usage_application || "NA"}
                                </h5>
                              </div>
                              <div className="item">
                                <h5>Roughness</h5>
                              </div>
                              <div className="item">
                                <h5>
                                  {this.state.productsDetail?.short_details
                                    ?.roughness || "NA"}
                                </h5>
                              </div>
                              <div className="item">
                                <h5>Color</h5>
                              </div>
                              <div className="item">
                                <h5>
                                  {this.state.productsDetail?.short_details
                                    ?.colour || "NA"}
                                </h5>
                              </div>
                              <div className="item">
                                <h5>Paper Size</h5>
                              </div>
                              <div className="item">
                                {" "}
                                <h5>
                                  {" "}
                                  {this.state.productsDetail?.short_details
                                    ?.paper_size || "NA"}
                                </h5>
                              </div>
                              <div className="item">
                                <h5>Product GSM</h5>
                              </div>
                              <div className="item">
                                <h5>
                                  {this.state.productsDetail?.short_details
                                    ?.product_gsm || "NA"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Row>
                      <Col xs={12} xm={12} md={4}>
                        <br />

                        <Button
                          className=""
                          variant="contained"
                          color="secondary"
                          style={{ margin: "10px", marginLeft: "4px" }}
                          onClick={() => {
                            this.setState({ openProductModal: false });
                          }}
                        >
                          Back
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal>
      </Container>
    );
  }
}
