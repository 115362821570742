const mainDomain = "https://core.prglobalresources.com/";
const imgMainDomain = "https://core.prglobalresources.com";

// const mainDomain = "http://localhost:5001/";

const Constants = {
  imgDomain: {
    imgMainDomain,
  },
  getUrls: {
    //get api's of pr paper
    getAdminProfile: mainDomain + "dash/admin/getAdminProfile",

    getAllCategory: mainDomain + "dash/category",

    getAllSubcategory: mainDomain + "dash/sub-category",
    getSingleSubcategory: mainDomain + "dash/sub-category/",

    getAllProducts: mainDomain + "dash/product",
    getProductById: mainDomain + "dash/product/",

    getAllContacts: mainDomain + "api/contact",
    getContactExcel: mainDomain + "api/contact/excelExport",

    getAllEnquiry: mainDomain + "dash/enquiry",
    getEnquiryExcel: mainDomain + "dash/enquiry/excelExport",

    getAllCertificate: mainDomain + "dash/certificate",
    getCertificateById: mainDomain + "dash/certificate/",

    getHomeDetails: mainDomain + "dash/home",
    getHomeDetailById: mainDomain + "dash/home/",

    getAllServices: mainDomain + "dash/service",
    getAllServiceById: mainDomain + "dash/service/",

    getAllWhoWeAre: mainDomain + "dash/whowe",
    getWhoWeAreById: mainDomain + "dash/whowe/",

    getAllwebContact: mainDomain + "dash/webcontact",
    getwebcontactById: mainDomain + "dash/webContact/",

    getAllEmailSignature: mainDomain + "dash/emailsign",
    getEmailSignatureById: mainDomain + "dash/emailsign/",

    //agriculture get api

    getAllArgiCategory: mainDomain + "agri/category",
    getAgriCategoryById: mainDomain + "agri/category/",

    getAllArgiSubcategory: mainDomain + "agri/sub-category",
    getAgriSubcategoryById: mainDomain + "agri/sub-category/",

    getAllAgriProducts: mainDomain + "agri/product",
    getAgriProductById: mainDomain + "agri/product/",

    getAllAgriHomeDetails: mainDomain + "agri/home",
    getAgriHomeById: mainDomain + "agri/home/",

    getAllAgriServices: mainDomain + "agri/service",
    getAgriServiceById: mainDomain + "agri/service/",

    getAllAgriEnquiry: mainDomain + "agri/enquiry",
    getagriEnquiryExcel: mainDomain + "agri/enquiry/excelExport",

    getAllAgriCertificate: mainDomain + "agri/certificate",
    getAgriCertificateById: mainDomain + "agri/certificate/",

    getAllAgriContact: mainDomain + "agri/contact",
    getagriContactExcel: mainDomain + "agri/contact/excelExport",

    Dashboard: mainDomain + "getDashboard",
  },
  postUrls: {
    //dashboard admin login
    adminLogin: mainDomain + "dash/admin/login",

    // post api of pr paper's
    Addcategory: mainDomain + "dash/category/create",
    AddSubcategory: mainDomain + "dash/sub-category/create",
    AddProducts: mainDomain + "dash/product/create",
    createCertificate: mainDomain + "dash/certificate/create",
    createHomeDetails: mainDomain + "dash/home/create",
    createServices: mainDomain + "dash/service/create",
    createWhoWeAre: mainDomain + "dash/whowe/create",
    createWebContact: mainDomain + "dash/webContact/create",
    createEmailSignature: mainDomain + "dash/emailsign/create",

    //psot api of pr agriculture

    AddagricultureCategory: mainDomain + "agri/category/create",
    AddAgriSubCategory: mainDomain + "agri/sub-category/create",
    AddAgriHomeDetails: mainDomain + "agri/home/create",
    AddAgriServices: mainDomain + "agri/service/create",
    AddAgriProducts: mainDomain + "agri/product/create",
    AddAgriCertificate: mainDomain + "agri/certificate/create",

    // pr paper delete Api's

    deleteCatgorys: mainDomain + "dash/category/delete/",
    deleteSubCategorys: mainDomain + "dash/sub-category/delete/",
    deleteProducts: mainDomain + "dash/product/delete/",
    deleteCertificates: mainDomain + "dash/certificate/delete/",
    deleteHomeDetails: mainDomain + "dash/home/delete/",
    deleteAllServices: mainDomain + "dash/service/delete/",
    deleteEnquiry: mainDomain + "dash/enquiry/delete/",
    deleteWhoWeAre: mainDomain + "dash/whowe/delete/",
    deleteWebContact: mainDomain + "dash/webContact/delete/",
    deleteEmailSignature: mainDomain + "dash/emailsign/delete/",

    // agriculture section delete api

    deleteAgriCategory: mainDomain + "agri/category/delete/",
    deleteAgriSubcategory: mainDomain + "agri/sub-category/delete/",
    deleteAgriHomeDetail: mainDomain + "agri/home/delete/",
    deleteAgriServices: mainDomain + "agri/service/delete/",
    deleteAgriProducts: mainDomain + "agri/product/delete/",
    deleteAgriEnquiry: mainDomain + "agri/enquiry/delete/",
    deleteAgriCertificate: mainDomain + "agri/certificate/delete/",
  },
  putUrls: {
    //update api's of pr paper
    updateCategory: mainDomain + "dash/category/update",

    updateSubCategory: mainDomain + "dash/sub-category/update",

    updateProduct: mainDomain + "dash/product/update",

    ReplyToContact: mainDomain + "api/contact/contactReply",
    UpdateContactStatus: mainDomain + "api/contact/statusUpdate",

    ReplyToEnquiry: mainDomain + "dash/enquiry/replyEnquiry",

    UpdateCertificates: mainDomain + "dash/certificate/update",

    UpdateHomedetails: mainDomain + "dash/home/update",

    UpdateAllServices: mainDomain + "dash/service/update",

    UpdateWhoWeAre: mainDomain + "dash/whowe/update",
    UpdateWebContact: mainDomain + "dash/webContact/update",
    updateEmailSignature: mainDomain + "dash/emailsign/update",

    //pr agriculture update api's

    updateAgricategory: mainDomain + "agri/category/update",
    updateAgriSubcategory: mainDomain + "agri/sub-category/update",
    updateAgriHomeDetail: mainDomain + "agri/home/update",
    updateAgriServices: mainDomain + "agri/service/update",
    updateAgriProducts: mainDomain + "agri/product/update",
    updateAgricertificate: mainDomain + "agri/certificate/update",
    ReplyAgriEnquiry: mainDomain + "agri/enquiry/replyEnquiry",

    ReplyAgriContact: mainDomain + "agri/contact/contactReply",
    updateAgricontactStatus: mainDomain + "agri/contact/statusUpdate",
  },
};
export default Constants;
