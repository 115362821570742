import React, { Component } from "react";
import axios from "axios";
// import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
  //   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { Form } from "reactstrap";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { co } from "co";
import init from "../helpers/WindowToken";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { makeStyles } from "@material-ui/core";
import { ReplyAll } from "@material-ui/icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const classes = makeStyles();
let view = "";
export default class UpdateProduct extends Component {
  state = {
    _id: "",
    getAllCategory: [],
    getAllSubCategory: [],
    product_name: "",
    category_id: "",
    sub_category_id: "",
    product_type: "",
    product_quality: "",
    product_price: "",
    price_for_min_quantity: "",

    colour: "",
    shape: "",
    material: "",
    detail_description: "",
    usage_application: "",
    roughness: "",
    paper_size: "",
    product_gsm: "",
    grade: "",

    message: "",
    loading: false,
    notification: false,
    redirect: false,
    images: "",

    images1: "",
    firstImage: "",
    firImg: null,
    isfirstImageOpen: false,
    firstImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    images2: "",
    secImg: null,
    isSecondImageOpen: false,
    secondImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    images3: "",
    thirdImg: null,
    isThirdImageOpen: false,
    ThirdImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    images4: "",
    forthImg: null,
    isForthImageOpen: false,
    ForthImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    // images5: "",
    // fifthImg: null,
    // isFifthImageOpen: false,
    // FifthImageCrop: {
    //   unit: "%",
    //   x: 0,
    //   y: 0,
    //   width: 100,
    //   height: 100,
    // },
  };

  componentDidMount() {
    let id = window.location.pathname.split("/")[3];
    this.setState({
      _id: id,
    });

    if (init() === "success") {
      axios.get(Constants.getUrls.getProductById + id).then((resp) => {
        console.log(resp);
        this.setState(
          {
            product_name: resp.data.data.product_name,
            category_id: resp.data.data.category_id,
            sub_category_id: resp.data.data.sub_category_id,
            product_type: resp.data.data.product_type,
            product_quality: resp.data.data.product_quality,
            product_price: resp.data.data.product_price,
            price_for_min_quantity: resp.data.data.price_for_min_quantity,
            // short_details: resp.data.data.short_details,

            colour: resp.data.data.short_details.colour,
            shape: resp.data.data.short_details.shape,
            material: resp.data.data.short_details.material,
            usage_application: resp.data.data.short_details.usage_application,
            roughness: resp.data.data.short_details.roughness,
            paper_size: resp.data.data.short_details.paper_size,
            product_gsm: resp.data.data.short_details.product_gsm,
            grade: resp.data.data.short_details.grade,

            detail_description: resp.data.data.detail_description,
            images: resp.data.data.images,
          },
          () => {
            if (init() === "success") {
              axios
                .get(Constants.getUrls.getAllCategory + `?limit=${100}`)
                .then((resp) => {
                  console.log(resp);
                  this.setState({
                    getAllCategory: resp.data.data.docs,
                  });
                });
            }
          }
        );
        axios
          .get(Constants.getUrls.getAllSubcategory + `?limit=${100}`)
          .then((resp) => {
            console.log(resp);
            this.setState({
              getAllSubCategory: resp.data.data.docs,
            });
          });
      });
    }
  }

  handleSubcategory = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    e.persist();
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      this.setState(
        {
          [e.target.name]: e.target.files[0],
        },
        () => {
          // console.log(e.target);
          this.refForImages(e);
        }
      );
    }
  };

  refForImages = (e) => {
    if (e.target.name === "images1") {
      this.setState({
        isfirstImageOpen: true,
        firImg: URL.createObjectURL(this.state.images1),
      });
    }

    if (e.target.name === "images2") {
      this.setState({
        isSecondImageOpen: true,
        secImg: URL.createObjectURL(this.state.images2),
      });
    }

    if (e.target.name === "images3") {
      this.setState({
        isThirdImageOpen: true,
        thirdImg: URL.createObjectURL(this.state.images3),
      });
    }

    if (e.target.name === "images4") {
      this.setState({
        isForthImageOpen: true,
        forthImg: URL.createObjectURL(this.state.images4),
      });
    }

    // if (e.target.name === "images5") {
    //   this.setState({
    //     isFifthImageOpen: true,
    //     fifthImg: URL.createObjectURL(this.state.images5),
    //   });
    // }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onFirstImageCropComplete = (crop) => {
    this.makeFirstClientCrop(crop);
  };

  onSecondImageCropComplete = (crop) => {
    this.makeSecondClientCrop(crop);
  };

  onThirdImageCropComplete = (crop) => {
    this.makeThirdClientCrop(crop);
  };

  onForthImageCropComplete = (crop) => {
    this.makeForthClientCrop(crop);
  };

  // onFifthImageCropComplete = (crop) => {
  //   this.makeFifthClientCrop(crop);
  // };

  onCropFirstChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ firstImageCrop: crop });
  };

  onCropSecondChange = (crop, percentCrop) => {
    this.setState({ secondImageCrop: crop });
  };

  onCropThirdChange = (crop, percentCrop) => {
    this.setState({ ThirdImageCrop: crop });
  };

  onCropForthChange = (crop, percentCrop) => {
    this.setState({ ForthImageCrop: crop });
  };

  // onCropFifththChange = (crop, percentCrop) => {
  //   this.setState({ FifthImageCrop: crop });
  // };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  async makeFirstClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const firstCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ firstCroppedImageUrl });
    }
  }

  async makeSecondClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const secondCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ secondCroppedImageUrl });
    }
  }

  async makeThirdClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const thirdCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ thirdCroppedImageUrl });
    }
  }

  async makeForthClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const forthCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ forthCroppedImageUrl });
    }
  }

  // async makeFifthClientCrop(crop) {
  //   if (this.imageRef && crop.width && crop.height) {
  //     const fifthCroppedImageUrl = await this.getCroppedImg(
  //       this.imageRef,
  //       crop,
  //       "newFile.jpeg"
  //     );
  //     this.setState({ fifthCroppedImageUrl });
  //   }
  // }

  handleChange(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        detail_description: content,
      },
      () => {
        console.log(this.state.detail_description);
      }
    );
  }

  UpdateProducts = async (e) => {
    e.preventDefault();
    let short_details = {};
    short_details.colour = this.state.colour;
    short_details.material = this.state.material;
    short_details.shape = this.state.shape;
    short_details.usage_application = this.state.usage_application;
    short_details.roughness = this.state.roughness;
    short_details.paper_size = this.state.paper_size;
    short_details.product_gsm = this.state.product_gsm;
    short_details.grade = this.state.grade;

    let payload = new FormData();
    payload.append("_id", this.state._id);
    payload.append("product_name", this.state.product_name);
    payload.append("category_id", this.state.category_id);
    payload.append("sub_category_id", this.state.sub_category_id);
    payload.append("product_type", this.state.product_type);
    payload.append("product_quality", this.state.product_quality);
    payload.append("product_price", this.state.product_price);
    payload.append("price_for_min_quantity", this.state.price_for_min_quantity);
    payload.append("short_details", JSON.stringify(short_details));
    // payload.append("colour", this.state.colour)
    // payload.append("shape", this.state.shape )
    // payload.append("material", this.state.material )
    payload.append("detail_description", this.state.detail_description);

    const firstSideImage = await fetch(this.state.firstCroppedImageUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], this.state.firstCroppedImageUrl, {
            type: blobFile.type,
          })
      );

    const SecondSideImage = await fetch(this.state.secondCroppedImageUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], this.state.secondCroppedImageUrl, {
            type: blobFile.type,
          })
      );

    const ThirdSideImage = await fetch(this.state.thirdCroppedImageUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], this.state.thirdCroppedImageUrl, {
            type: blobFile.type,
          })
      );

    const ForthSideImage = await fetch(this.state.forthCroppedImageUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], this.state.forthCroppedImageUrl, {
            type: blobFile.type,
          })
      );

    // const FifthSideImage = await fetch(this.state.fifthCroppedImageUrl)
    //   .then((r) => r.blob())
    //   .then(
    //     (blobFile) =>
    //       new File([blobFile], this.state.fifthCroppedImageUrl, {
    //         type: blobFile.type,
    //       })
    //   );

    if (this.state.firstCroppedImageUrl) {
      payload.append("images", firstSideImage);
    } else {
      payload.append("images", this.state.images1);
    }

    if (this.state.secondCroppedImageUrl) {
      payload.append("images", SecondSideImage);
    } else {
      payload.append("images", this.state.images2);
    }

    if (this.state.thirdCroppedImageUrl) {
      payload.append("images", ThirdSideImage);
    } else {
      payload.append("images", this.state.images3);
    }

    if (this.state.forthCroppedImageUrl) {
      payload.append("images", ForthSideImage);
    } else {
      payload.append("images", this.state.images4);
    }

    // if (this.state.fifthCroppedImageUrl) {
    //   payload.append("images", FifthSideImage);
    // } else {
    //   payload.append("images", this.state.images5);
    // }

    // [...this.state.images].map((image) => {
    //   payload.append("images", image);
    // });
    console.log(payload);

    // console.log(this.state.product_price);
    // console.log(this.state.product_gsm);
    // console.log(short_details.colour);
    // console.log(this.state.short_details.colour);

    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios
        .put(Constants.putUrls.updateProduct + "/" + this.state._id, payload)
        .then((resp) => {
          console.log(resp.data);
          this.setState({
            loading: false,
            notification: true,
            message: resp?.data?.message,
          });

          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 2000);
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({
            message:
              "No Sub-Category Found For This Category Plaese Select Another Category",
            notification: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: false,
            });
          }, 5000);
        });
    }
  };

  handleSelect = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (init() === "success") {
          axios
            .get(
              Constants.getUrls.getAllSubcategory +
                "?cateId=" +
                this.state.category_id
            )
            .then((resp) => {
              // console.log(resp);
              this.setState({
                getAllSubCategory: resp.data.data.docs,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    );
  };

  handleSelectMinQuantity = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.roughness);
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/products" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Update Products</Typography>
            </Grid>
          </Grid>

          {/* After Getting api add Snackbar notification here  */}

          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={true}
              color={"green"}
              autoHideDuration={5000}
              message={this.state.message}
            ></Snackbar>
          ) : null}

          <div
            style={{
              background: "#5b5757",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              border: "0",
              display: "flex",
              position: "relative",
              fontSize: ".875rem",
              minWidth: "0",
              wordWrap: " break-word",
              boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
              marginTop: "30px",
              borderRadius: "6px",
              marginBottom: "30px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>

            <form onSubmit={this.UpdateProducts}>
              <Grid container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Product Name"
                    name="product_name"
                    variant="outlined"
                    required={true}
                    value={this.state.product_name}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <FormControl
                    // style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      style={{
                        marginLeft: "15px",
                      }}
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Category
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      value={this.state.category_id}
                      inputProps={{
                        name: "category_id",
                        id: "simple-select",
                        onChange: this.handleSelect,
                        required: true,
                      }}
                    >
                      {this.state.getAllCategory.map((category, i) => (
                        <MenuItem key={i} value={category._id}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={4}>
                  <FormControl
                    // style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      style={{
                        marginLeft: "15px",
                      }}
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Sub Category
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      value={this.state.sub_category_id}
                      inputProps={{
                        name: "sub_category_id",
                        id: "simple-select",
                        onChange: this.handleSubcategory,
                        required: true,
                      }}
                    >
                      {this.state.getAllSubCategory.length > 0 ? (
                        this.state.getAllSubCategory.map((sub_category, i) => (
                          <MenuItem key={i} value={sub_category._id}>
                            {sub_category.title}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>{"No Sub-Category Available"}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Product Type"
                    name="product_type"
                    variant="outlined"
                    // required={true}
                    value={this.state.product_type}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Product Quality"
                    name="product_quality"
                    variant="outlined"
                    // required={true}
                    value={this.state.product_quality}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Product Price"
                    name="product_price"
                    variant="outlined"
                    required={true}
                    value={this.state.product_price}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                {/* <Grid item sm={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      style={{
                        marginLeft: "15px",
                      }}
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Minimum Quantity
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      value={this.state.price_for_min_quantity}
                      inputProps={{
                        name: "price_for_min_quantity",
                        id: "simple-select",
                        onChange: this.handleSelectMinQuantity,
                      }}
                    >
                      <MenuItem value="ton">Ton</MenuItem>
                      <MenuItem value="quintal">Quintal</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Colour"
                    name="colour"
                    variant="outlined"
                    // required={true}
                    value={this.state.colour}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Shapes"
                    name="shape"
                    variant="outlined"
                    // required={true}
                    value={this.state.shape}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Materials"
                    name="material"
                    variant="outlined"
                    // required={true}
                    value={this.state.material}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Usage/Application"
                    name="usage_application"
                    variant="outlined"
                    value={this.state.usage_application}
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Roughness"
                    name="roughness"
                    variant="outlined"
                    value={this.state.roughness}
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Paper Size"
                    name="paper_size"
                    variant="outlined"
                    value={this.state.paper_size}
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Product GSM"
                    name="product_gsm"
                    variant="outlined"
                    value={this.state.product_gsm}
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Grade"
                    name="grade"
                    variant="outlined"
                    value={this.state.grade}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    helperText="Image 1"
                    size="medium"
                    label="Images"
                    name="images1"
                    variant="outlined"
                    onChange={this.handleFile}
                    // required={true}
                    // value={this.state.images}
                    inputProps={{ accept: "image/*" }}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      marginBottom: "10px",
                      marginRight: "20px",
                      borderRadius: "5px",
                    }}
                    src={
                      "https://core.prglobalresources.com" +
                      this.state.images[0]
                    }
                  />
                </Grid>
                {/* First Image Crop Modal */}
                <Modal
                  open={this.state.isfirstImageOpen}
                  onClose={() => {
                    this.setState({
                      isfirstImageOpen: false,
                    });
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      height: "100%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      overflowY: "scroll",
                    }}
                  >
                    {this.state.firImg && (
                      <ReactCrop
                        src={this.state.firImg}
                        crop={this.state.firstImageCrop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onFirstImageCropComplete}
                        onChange={this.onCropFirstChange}
                        crossorigin="anonymous"
                        aspect={4 / 3}
                      />
                    )}

                    {this.state.firstCroppedImageUrl && (
                      <img
                        alt="Crop"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          marginLeft: "1rem",
                        }}
                        src={this.state.firstCroppedImageUrl}
                      />
                    )}

                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      style={{
                        position: "fixed",
                        top: "10%",
                        right: "2%",
                        // transform: "translate(-50%, -50%)",
                        zIndex: "10",
                      }}
                      onClick={() => {
                        this.setState({
                          isfirstImageOpen: false,
                        });
                      }}
                    >
                      Crop Image
                    </Button>
                  </Box>
                </Modal>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    helperText="Image 2"
                    onChange={this.handleFile}
                    label="Images"
                    name="images2"
                    variant="outlined"
                    // required={true}
                    inputProps={{ accept: "image/*" }}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      marginBottom: "10px",
                      marginRight: "20px",
                      borderRadius: "5px",
                    }}
                    src={
                      "https://core.prglobalresources.com" +
                      this.state.images[1]
                    }
                  />
                </Grid>

                <Modal
                  open={this.state.isSecondImageOpen}
                  onClose={() => {
                    this.setState({
                      isSecondImageOpen: false,
                    });
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      height: "100%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      overflowY: "scroll",
                      p: 4,
                    }}
                  >
                    {this.state.secImg && (
                      <ReactCrop
                        src={this.state.secImg}
                        crop={this.state.secondImageCrop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onSecondImageCropComplete}
                        onChange={this.onCropSecondChange}
                        crossorigin="anonymous"
                        aspect={4 / 3}
                      />
                    )}

                    {this.state.secondCroppedImageUrl && (
                      <img
                        alt="Crop"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          marginLeft: "1rem",
                        }}
                        src={this.state.secondCroppedImageUrl}
                      />
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      style={{
                        position: "absolute",
                        top: "12%",
                        right: "2%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "10",
                      }}
                      onClick={() => {
                        this.setState({
                          isSecondImageOpen: false,
                        });
                      }}
                    >
                      Crop Image
                    </Button>
                  </Box>
                </Modal>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    helperText="Image 3"
                    onChange={this.handleFile}
                    label="Images"
                    name="images3"
                    variant="outlined"
                    // required={true}
                    inputProps={{ accept: "image/*" }}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />

                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      marginBottom: "10px",
                      marginRight: "20px",
                      borderRadius: "5px",
                    }}
                    src={
                      "https://core.prglobalresources.com" +
                      this.state.images[2]
                    }
                  />
                </Grid>

                <Modal
                  open={this.state.isThirdImageOpen}
                  onClose={() => {
                    this.setState({
                      isThirdImageOpen: false,
                    });
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      height: "100%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      overflow: "scroll",
                      p: 4,
                    }}
                  >
                    {this.state.thirdImg && (
                      <ReactCrop
                        src={this.state.thirdImg}
                        crop={this.state.ThirdImageCrop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onThirdImageCropComplete}
                        onChange={this.onCropThirdChange}
                        crossorigin="anonymous"
                        aspect={4 / 3}
                      />
                    )}

                    {this.state.thirdCroppedImageUrl && (
                      <img
                        alt="Crop"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          marginLeft: "1rem",
                        }}
                        src={this.state.thirdCroppedImageUrl}
                      />
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      style={{
                        position: "absolute",
                        top: "12%",
                        right: "2%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "10",
                      }}
                      onClick={() => {
                        this.setState({
                          isThirdImageOpen: false,
                        });
                      }}
                    >
                      Crop Image
                    </Button>
                  </Box>
                </Modal>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    helperText="Image 4"
                    onChange={this.handleFile}
                    label="Images"
                    name="images4"
                    variant="outlined"
                    // required={true}
                    inputProps={{ accept: "image/*" }}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />

                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      marginBottom: "10px",
                      marginRight: "20px",
                      borderRadius: "5px",
                    }}
                    src={
                      "https://core.prglobalresources.com" +
                      this.state.images[3]
                    }
                  />
                </Grid>

                <Modal
                  open={this.state.isForthImageOpen}
                  onClose={() => {
                    this.setState({
                      isForthImageOpen: false,
                    });
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      height: "100%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      overflowY: "scroll",
                      p: 4,
                    }}
                  >
                    {this.state.forthImg && (
                      <ReactCrop
                        src={this.state.forthImg}
                        crop={this.state.ForthImageCrop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onForthImageCropComplete}
                        onChange={this.onCropForthChange}
                        crossorigin="anonymous"
                        aspect={4 / 3}
                      />
                    )}

                    {this.state.forthCroppedImageUrl && (
                      <img
                        alt="Crop"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          marginLeft: "1rem",
                        }}
                        src={this.state.forthCroppedImageUrl}
                      />
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      style={{
                        position: "absolute",
                        top: "12%",
                        right: "2%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "10",
                      }}
                      onClick={() => {
                        this.setState({
                          isForthImageOpen: false,
                        });
                      }}
                    >
                      Crop Image
                    </Button>
                  </Box>
                </Modal>

                {/* <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    helperText="Image 5"
                    onChange={this.handleFile}
                    label="Images"
                    name="images5"
                    variant="outlined"
                    // required={true}
                    inputProps={{ accept: "image/*" }}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      marginBottom: "10px",
                      marginRight: "20px",
                      borderRadius: "5px",
                    }}
                    src={
                      "https://core.prglobalresources.com" +
                      this.state.images[4]
                    }
                  />
                </Grid>

                <Modal
                  open={this.state.isFifthImageOpen}
                  onClose={() => {
                    this.setState({
                      isFifthImageOpen: false,
                    });
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      height: "80%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    {this.state.fifthImg && (
                      <ReactCrop
                        src={this.state.fifthImg}
                        crop={this.state.FifthImageCrop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onFifthImageCropComplete}
                        onChange={this.onCropFifththChange}
                        crossorigin="anonymous"
                        aspect={4 / 3}
                      />
                    )}

                    {this.state.fifthCroppedImageUrl && (
                      <img
                        alt="Crop"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          marginLeft: "1rem",
                        }}
                        src={this.state.fifthCroppedImageUrl}
                      />
                    )}

                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      style={{
                        position: "absolute",
                        top: "12%",
                        right: "2%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "10",
                      }}
                      onClick={() => {
                        this.setState({
                          isFifthImageOpen: false,
                        });
                      }}
                    >
                      Crop Image
                    </Button>
                  </Box>
                </Modal> */}

                <div
                  style={{
                    color: "black",
                    marginTop: "1rem",
                    marginLeft: "0.6rem",
                  }}
                >
                  <SunEditor
                    // width="50%"
                    setOptions={{
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize"],
                        ["paragraphStyle", "blockquote"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                        ],
                        ["fontColor", "hiliteColor"],
                        ["align", "list", "lineHeight"],
                        ["outdent", "indent"],

                        ["table", "horizontalRule", "link", "image", "video"],
                        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                        // ['imageGallery'], // You must add the "imageGalleryUrl".
                        ["fullScreen", "showBlocks", "codeView"],
                        ["preview", "print"],
                        ["removeFormat"],

                        ["save", "template"],
                        // '/', Line break
                      ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                      defaultTag: "div",
                      defaultStyle: "font-family: Arial",
                      minHeight: "250px",
                      showPathLabel: false,
                      // font: sortedFontOptions,
                    }}
                    setContents={this.state.detail_description}
                    onChange={this.handleChange.bind(this)}
                    // required={true}
                  />
                </div>
              </Grid>

              <br />
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    width: "15%",
                    marginTop: "35px",
                  }}
                >
                  {this.state.loading ? "Updating.." : "Update Product"}
                </Button>
                <Link
                  to={{
                    pathname: "/products",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </Container>
      );
    }
  }
}
