import {
  DashboardOutlined,
  Group,
  GroupAddOutlined,
  ExtensionTwoTone,
  Copyright,
  BookmarkBorderOutlined,
  EditAttributesTwoTone,
  SportsEsportsOutlined,
  PolicyOutlined,
  AddIcCallOutlined,
  HelpOutline,
  CurrencyRupee,
  NotificationImportantOutlined,
  AppsOutlined,
  Subject,
  SmartphoneOutlined,
} from "@material-ui/icons";
// SummarizeTwoToneIcon
import React, { Component } from "react";
// import CategoryIcon from "@material-ui/icons/Category";
import Dashboard from "./App/Main/Dashboard";
// import SubAdmins from "./App/Main/SubAdmins";

// import UserList from "./App/Main/UserList";
// import HowToPlay from "./App/Main/HowToPlay";

// import GamePolicy from "./App/Main/GamePolicy";

// import HelpandFAQ from "./App/Main/HelpandFAQ";

// pr paper section

import Subcategory from "./App/Main/Subcategory";
import Category from "./App/Main/Category";
import Products from "./App/Main/Products";
import Contact from "./App/Main/Contact";
import Enquiry from "./App/Main/Enquiry";
import Certificate from "./App/Main/Certificate";
import HomeScreen from "./App/Main/HomeScreen";
import Services from "./App/Main/Services";
import whoWeAre from "./App/Main/whoWeAre";
import WebContact from "./App/Main/WebContact";
// import emailsignature from "./App/Main/emailsignature";

//agriculture section

import AgriCategory from "./App/Main/Agricategory";
import AgriSubcategory from "./App/Main/AgriSubcategory";
import AgriHome from "./App/Main/AgriHome";
import AgriServices from "./App/Main/AgriServices";
import AgriProduct from "./App/Main/AgriProduct";
import AgriEnquiry from "./App/Main/AgriEnquiry";
import AgriCertificate from "./App/Main/AgriCertificate";
import AgriContact from "./App/Main/AgriContact";

// const x = false;
const routes = {
  Dashboard: [
    {
      title: "Dashboard",
      path: "/dashboard",
      component: Dashboard,
      icon: <DashboardOutlined />,
      exact: true,
      // pathId: "61d48beb4e865687542a4d6e",
      // role:"superadmin",
    },
  ],

  // Home: [
  //   {
  //     title: "Home Screen",
  //     path: "/homeScreen",
  //     component: HomeScreen,
  //     icon: <Subject />,
  //     exact: true,
  //   },
  // ],

  // Category: [
  //   {
  //     title: "Category",
  //     path: "/category",
  //     component: Category,
  //     icon: <EditAttributesTwoTone />,
  //     exact: true,
  //   },
  // ],

  // SubCategory: [
  //   {
  //     title: "Sub Category",
  //     path: "/subcateory",
  //     component: Subcategory,
  //     icon: <EditAttributesTwoTone />,
  //     exact: true,
  //   },
  // ],

  // Products: [
  //   {
  //     title: "Products",
  //     path: "/products",
  //     component: Products,
  //     icon: <EditAttributesTwoTone />,
  //     exact: true,
  //   },
  // ],

  // Contact: [
  //   {
  //     title: "Contact Us",
  //     path: "/contact",
  //     component: Contact,
  //     icon: <Copyright />,
  //     exact: true,
  //   },
  // ],

  // Enquiry: [
  //   {
  //     title: "Enquiry",
  //     path: "/enquiry",
  //     component: Enquiry,
  //     icon: <AddIcCallOutlined />,
  //     exact: true,
  //   },
  // ],

  // Certificate: [
  //   {
  //     title: "Certificate",
  //     path: "/certificate",
  //     component: Certificate,
  //     icon: <Copyright />,
  //     exact: true,
  //   },
  // ],

  // Services: [
  //   {
  //     title: "Services",
  //     path: "/services",
  //     component: Services,
  //     icon: <GroupAddOutlined />,
  //     exact: true,
  //   },
  // ],

  PR_Papers: [
    {
      title: "Home Screen",
      path: "/homeScreen",
      component: HomeScreen,
      icon: <Subject />,
      exact: true,
    },

    {
      title: "Category",
      path: "/category",
      component: Category,
      icon: <EditAttributesTwoTone />,
      exact: true,
    },

    {
      title: "Sub Category",
      path: "/subcateory",
      component: Subcategory,
      icon: <EditAttributesTwoTone />,
      exact: true,
    },

    {
      title: "Products",
      path: "/products",
      component: Products,
      icon: <EditAttributesTwoTone />,
      exact: true,
    },

    {
      title: "Services",
      path: "/services",
      component: Services,
      icon: <GroupAddOutlined />,
      exact: true,
    },

    {
      title: "Who We Are",
      path: "/whoWeAre",
      component: whoWeAre,
      icon: <SmartphoneOutlined />,
      exact: true,
    },

    {
      title: "Enquiry",
      path: "/enquiry",
      component: Enquiry,
      icon: <AddIcCallOutlined />,
      exact: true,
    },
    {
      title: "Certificate",
      path: "/certificate",
      component: Certificate,
      icon: <Copyright />,
      exact: true,
    },

    {
      title: "Contact Us",
      path: "/contact",
      component: Contact,
      icon: <Copyright />,
      exact: true,
    },

    {
      title: "Web Contact",
      path: "/webcontact",
      component: WebContact,
      icon: <Copyright />,
      exact: true,
    },
    // {
    //   title: "Signature",
    //   path: "/emailSignature",
    //   component: emailsignature,
    //   icon: <ExtensionTwoTone />,
    //   exact: true,
    // },
  ],

  PR_agri: [
    {
      title: "Category",
      path: "/categoryAgri",
      component: AgriCategory,
      icon: <EditAttributesTwoTone />,
      exact: true,
    },

    {
      title: "Sub Category",
      path: "/Agrisubcategory",
      component: AgriSubcategory,
      icon: <EditAttributesTwoTone />,
      exact: true,
    },

    {
      title: "Products",
      path: "/Agriproducts",
      component: AgriProduct,
      icon: <EditAttributesTwoTone />,
      exact: true,
    },

    {
      title: "Home Screen",
      path: "/agrihome",
      component: AgriHome,
      icon: <Subject />,
      exact: true,
    },
    {
      title: "Services",
      path: "/Agriservices",
      component: AgriServices,
      icon: <GroupAddOutlined />,
      exact: true,
    },

    {
      title: "Enquiry",
      path: "/Agrienquiry",
      component: AgriEnquiry,
      icon: <AddIcCallOutlined />,
      exact: true,
    },

    {
      title: "Certificate",
      path: "/Agricertificate",
      component: AgriCertificate,
      icon: <Copyright />,
      exact: true,
    },

    {
      title: "Contact",
      path: "/Agricontact",
      component: AgriContact,
      icon: <Copyright />,
      exact: true,
    },
  ],
};

export default routes;
