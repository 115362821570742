// constructor(props) {
//   super(props)

//   this.state = {

//   }
// }
import {
  DashboardOutlined,
  Group,
  GroupAddOutlined,
  ExtensionTwoTone,
  Copyright,
  BookmarkBorderOutlined,
  EditAttributesTwoTone,
  SportsEsportsOutlined,
  PolicyOutlined,
  AddIcCallOutlined,
  HelpOutline,
  CurrencyRupee,
  NotificationImportantOutlined,
  AppsOutlined,
  Subject,
  SmartphoneOutlined,
  NotificationImportantRounded,
  NotificationsNoneOutlined,
  SmartphoneTwoTone,
  SmartphoneSharp,
  HelpOutlineRounded,
  RepeatOneOutlined,
  Report,
  SubjectOutlined,
} from "@material-ui/icons";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
  ListSubheader,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  AccordionActions,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, ExpandMoreOutlined } from "@material-ui/icons";
import axios from "axios";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import routes from "../../../routes";
import Constants from "../../Variables/Constants";
import "../../../App.css";

export default class Sidebar extends Component {
  state = {
    role: "",
    x: false,
    filterdRoutes: [],
    openContest: false,
    openStates: false,
    openBlogs: false,
    openGame: false,
    openNotification: false,
    openAdmin: false,
    openUser: false,
    openApp: false,
    openReport: false,
    openHelpAndQuery: false,
    current: -1,
  };

  componentDidMount() {
    // this.getProfile();
    // getProfile = () => {
    // let filtValue = [];
    // axios.get(Constants.getUrls.getProfile).then((res) => {
    //   console.log(res);
    //   res.data.Data.accessAreas.forEach((val) => {
    //     //  console.log(val)
    //     routes.forEach((route) => {
    //       if (route.pathId === val._id) {
    //         filtValue.push(route);
    //  console.log(route)
    //  console.log(filtValue)
    //     }
    //   });
    // });
    // this.setState({
    //   filterdRoutes: filtValue,
    // });
    // console.log(this.state.role);
    // });
    // };
    // co
    console.log(this.props);
  }

  changeState = (panel) => (e, newvalue) => {
    this.setState({
      current: newvalue ? panel : -1,
    });
  };

  // handleAdmin = () => {
  //   let b = !this.state.openAdmin;
  //   this.setState({
  //     openAdmin: b,
  //   });
  // };
  // handleUser = () => {
  //   let b = !this.state.openUser;
  //   this.setState({
  //     openUser: b,
  //   });
  // };
  // handleContest = () => {
  //   let b = !this.state.openContest;
  //   this.setState({
  //     openContest: b,
  //   });
  // };

  // handleStates = () => {
  //   let b = !this.state.openStates;
  //   this.setState({
  //     openStates: b,
  //   });
  // };

  // handleApp = () => {
  //   let b = !this.state.openApp;
  //   this.setState({
  //     openApp: b,
  //   });
  // };
  // handleNotification = () => {
  //   let b = !this.state.openNotification;
  //   this.setState({
  //     openNotification: b,
  //   });
  // };

  // handleReport = () => {
  //   let b = !this.state.openReport;
  //   this.setState({
  //     openReport: b,
  //   });
  // };
  // handleBlogs = () => {
  //   let b = !this.state.openBlogs;
  //   this.setState({
  //     openBlogs: b,
  //   });
  // };
  // handleGame = () => {
  //   let b = !this.state.openGame;
  //   this.setState({
  //     openGame: b,
  //   });
  // };
  // handleHelpAndQuery = () => {
  //   let b = !this.state.openHelpAndQuery;
  //   this.setState({
  //     openHelpAndQuery: b,
  //   });
  // };
  render() {
    return (
      // <div style={{overflowY: "scroll", height: "100vh"}}>
      <List
        component="nav"
        style={{
          height: "100vh",
          borderRight: "1px solid #ddd",
          overflowY: "scroll",
        }}
        // className='Accordian moved  '
      >
        {/* {routes.map((route, index) => (
         
         
          //  route.role===this.state.role ? (

          <NavLink
            to={route.path}
            key={index}
            style={{ color: "inherit", textDecoration: "none" }}
            activeClassName={"navactive"}
          >
            <ListItem button>
              <ListItemText
                children={
                  <Typography
                    variant={"body2"}
                    style={{ fontSize: 14 }}
                    color="textPrimary"
                  >
                    <div style={{display: "flex", alignItems: "center"}} >
                
                      <span style={{paddingRight: "10px"}}> {route.icon} </span>
                     
                      <span style={{paddingLeft: "10px"}}> {route.title.toUpperCase() } </span>
                    </div>
                  
                  </Typography>
                }
                style={{ fontSize: 12 }}
              />
            </ListItem>
          </NavLink>
          //  ) : null
         
        ))} */}

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Dashboard.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Home.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion 
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
         >
        {routes.smartAnalytics.map((route , index) => (
         <NavLink
         to={route.path}
         key={index}
         style={{color : "inherit" , textDecoration : "none"}}
         >
          <ListItem button>
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.title.toUpperCase()} />
          </ListItem>
         </NavLink> 
        ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Category.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.SubCategory.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Products.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 1}
          onChange={this.changeState(1)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <AppsOutlined />
            </ListItemIcon>
            <ListItemText primary={"Products".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "20px" }}>
              {routes.Products.map((route, index) => (
                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Contact.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Enquiry.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Certificate.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        <Accordion
          expanded={this.state.current === 1}
          onChange={this.changeState(1)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <AppsOutlined />
            </ListItemIcon>
            <ListItemText primary={"PR Papers".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "20px" }}>
              {routes.PR_Papers.map((route, index) => (
                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={this.state.current === 2}
          onChange={this.changeState(2)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <AppsOutlined />
            </ListItemIcon>
            <ListItemText primary={"PR Agriculture".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "20px" }}>
              {routes.PR_agri.map((route, index) => (
                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        {/* 
        <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Services.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Certificate.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Contact.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion> */}

        {/* <Accordion 
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
          
        >
          <AccordionSummary  expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary={"Admin".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.Admin.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          // style={{ backgroundColor: "#303030"  }}
          expanded={this.state.current === 1}
          onChange={this.changeState(1)}
          // className=".MuiAccordion-root.Mui-expanded"
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <GroupAddOutlined />
            </ListItemIcon>
            <ListItemText primary={"User".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.User.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 3}
          onChange={this.changeState(3)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <EditAttributesTwoTone />
            </ListItemIcon>
            <ListItemText primary={"Blogs".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.Blogs.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 4}
          onChange={this.changeState(4)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <SportsEsportsOutlined />
            </ListItemIcon>
            <ListItemText primary={"Rules Of Game".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.RulesOf_Game.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 5}
          onChange={this.changeState(5)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <HelpOutlineRounded></HelpOutlineRounded>
            </ListItemIcon>
            <ListItemText primary={"States".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.States.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 6}
          onChange={this.changeState(6)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <SmartphoneOutlined />
            </ListItemIcon>
            <ListItemText primary={"App".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.App.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* 
        <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 7}
          onChange={this.changeState(7)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <NotificationsNoneOutlined></NotificationsNoneOutlined>
            </ListItemIcon>
            <ListItemText primary={"Notification".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.Notification.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 8}
          onChange={this.changeState(8)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <SubjectOutlined></SubjectOutlined>
            </ListItemIcon>
            <ListItemText primary={"Report".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.Report.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          style={{ marginBottom : "30px" }}
          expanded={this.state.current === 9}
          onChange={this.changeState(9)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <SmartphoneOutlined />
            </ListItemIcon>
            <ListItemText primary={"Help And Query".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.HelpAndQuery.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none"}}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 8}
          onChange={this.changeState(8)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <SubjectOutlined></SubjectOutlined>
            </ListItemIcon>
            <ListItemText primary={"HelpAndQuery".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.Report.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}

        {/*         
        <Accordion
          // style={{ backgroundColor: "#303030" }}
          expanded={this.state.current === 9}
          onChange={this.changeState(9)}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <ListItemIcon>
              <SubjectOutlined></SubjectOutlined>
            </ListItemIcon>
            <ListItemText primary={"HelpAndQuery".toUpperCase()} />
          </AccordionSummary>

          <AccordionDetails>
            <List component="div" disablePadding style={{ marginLeft: "50px" }}>
              {routes.HelpAndQuery.map((route, index) => (
                //  route.role===this.state.role ? (

                <NavLink
                  to={route.path}
                  key={index}
                  style={{ color: "inherit", textDecoration: "none" }}
                  //  activeClassName={"navactive"}
                >
                  <ListItem button sx={{ pl: 4 }}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.title.toUpperCase()} />
                  </ListItem>
                </NavLink>
                //  ) : null
              ))}
            </List>
          </AccordionDetails>
        </Accordion> */}
        {/* <ListItem button onClick={this.handleAdmin}>
         <ListItemIcon>
         <Group />
          </ListItemIcon>
          <ListItemText primary={"Admin".toUpperCase()} />
          {this.state.openAdmin ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openAdmin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.Admin.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
       </Collapse> */}

        {/* <ListItem button onClick={this.handleUser}>
          <ListItemIcon>
            <GroupAddOutlined />
          </ListItemIcon>
          <ListItemText primary={"User".toUpperCase()} />
          {this.state.openUser ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openUser} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.User.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}

        {/* <ListItem button onClick={this.handleContest}>
          <ListItemIcon>
            <Copyright />
          </ListItemIcon>
          <ListItemText primary={"Contest".toUpperCase()} />
          {this.state.openContest ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openContest} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.Contest.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}

        {/* <ListItem button onClick={this.handleBlogs}>
          <ListItemIcon>
            <EditAttributesTwoTone />
          </ListItemIcon>
          <ListItemText primary={"Blogs".toUpperCase()} />
          {this.state.openBlogs ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openBlogs} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.Blogs.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}

        {/* <ListItem button onClick={this.handleGame}>
          <ListItemIcon>
            <SportsEsportsOutlined />
          </ListItemIcon>
          <ListItemText primary={"Rules Of Game".toUpperCase()} />
          {this.state.openGame ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openGame} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.RulesOf_Game.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}

        {/* <ListItem button onClick={this.handleStates}>
          <ListItemIcon>
            <HelpOutlineRounded></HelpOutlineRounded>
          </ListItemIcon>
          <ListItemText primary={"States".toUpperCase()} />
          {this.state.openStates ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openStates} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.States.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}

        {/* <ListItem button onClick={this.handleApp}>
          <ListItemIcon>
            <SmartphoneOutlined />
          </ListItemIcon>
          <ListItemText primary={"App".toUpperCase()} />
          {this.state.openApp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openApp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.App.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}

        {/* <ListItem button onClick={this.handleNotification}>
          <ListItemIcon>
            <NotificationsNoneOutlined></NotificationsNoneOutlined>
          </ListItemIcon>
          <ListItemText primary={"Notification".toUpperCase()} />
          {this.state.openNotification ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openNotification} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.Notification.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}
        {/* <ListItem button onClick={this.handleReport}>
          <ListItemIcon>
            <SubjectOutlined></SubjectOutlined>
          </ListItemIcon>
          <ListItemText primary={"Report".toUpperCase()} />
          {this.state.openReport ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openReport} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.Report.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}

        {/* help and query start */}
        {/* <ListItem button onClick={this.handleHelpAndQuery}>
          <ListItemIcon>
            <SubjectOutlined></SubjectOutlined>
          </ListItemIcon>
          <ListItemText primary={"HelpAndQuery".toUpperCase()} />
          {this.state.openHelpAndQuery ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openHelpAndQuery} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: "50px" }}>
            {routes.HelpAndQuery.map((route, index) => (
              //  route.role===this.state.role ? (

              <NavLink
                to={route.path}
                key={index}
                style={{ color: "inherit", textDecoration: "none" }}
                //  activeClassName={"navactive"}
              >
                <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title.toUpperCase()} />
                </ListItem>
              </NavLink>
              //  ) : null
            ))}
          </List>
        </Collapse> */}
      </List>
      // </div>
    );
  }
}
