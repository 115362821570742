import {
  Chart,
  PieSeries,
  Title,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
  // CircularProgressProps
  CircularProgress,
} from "@material-ui/core";
import {
  // Container,
  // Grid,
  // Paper,
  // Typography,
  Card,
  CardActions,
  CardContent,
  // Button,
  // TextField,
} from "@material-ui/core";
import { Animation } from "@devexpress/dx-react-chart";
import { Legend } from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, HoverState } from "@devexpress/dx-react-chart";
import { Pagination, PaginationItem } from "@material-ui/lab";
// export 'default' (imported as 'Label') was not found in '@devexpress/dx-react-chart-material-ui' (possible exports: AreaSeries, ArgumentAxis, BarSeries, Chart, Legend, LineSeries, PieSeries, ScatterSeries, SplineSeries, Title, Tooltip, ValueAxis, ZoomAndPan)
import {
  PersonAddOutlined,
  ArrowDownward,
  Copyright,
  ComputerOutlined,
  AccountBalance,
} from "@material-ui/icons";
import Modal from "react-awesome-modal";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Constants from "../Variables/Constants";
import CardBox from "../Main/Card";
import init from "../../helpers/WindowToken";
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import "../../App.css";
import "../../App.css";
// import io from "socket.io-client";

import React, { Component } from "react";
import { Link } from "react-router-dom";
// const socket = io.connect("https://core.stocks10.com");
// const socket = io.connect("http://localhost:8084");

export default class Dashboard extends Component {
  state = {
    totalUsers: "",
    runningContests: 0,
    cpu: "",
    memory: "",
    client_count: 0,
    bank_added: 0,
    cash_bonus: 0,
    winnings: 0,
    total_withdrawn: 0,
    users_bankAdded: "",
    users_cashbonus: "",
    referal_bonus: "",
    users_referalBonus: "",
    data: [],
    limit: 10,
    page: 1,
    nextPage: false,
    prevPage: false,
    totaldocs: 0,
    totalpage: 1,
    getAllContest: [],
  };

  // handleChange = (event) => {
  //   this.setState({
  //     spacing: Number(event.target.value),
  //   });
  // };

  componentDidMount() {
    // this.getData();
    // this.getUserList();
    // this.core();
    // this.users_count();
    // this.client_count();
    // this.getContest();
  }

  // users_count = () => {
  //   socket.on("user_count", (data) => {
  //     this.setState({
  //       totalUsers: data,
  //     });
  //   });
  // };
  // client_count = () => {
  //   socket.io.on("clients_count", (data) => {
  //     console.log(data);
  //     this.setState({
  //       client_count: data,
  //     });
  //   });
  // };

  // core = () => {
  //   socket.on("core_server", (data) => {
  //     // console.log(data);
  //     this.setState(
  //       {
  //         cpu: data.cpu,
  //         memory: data.memory,
  //       },
  //       () => {
  //          socket.io.connect();
  //         console.log("socket connected");
  //       }
  //     );
  //   });
  // };
  componentWillUnmount() {
    // console.log("socket disconnected");
    // socket.disconnect();
  }
  // getUserList = () => {
  //   // console.log(this.state.totalUsers);
  //   axios
  //     .get(Constants.getUrls.getUserList + `?user_type=normal`)
  //     .then((resp) => {
  //       console.log(resp);
  //       this.setState({
  //         totalUsers: resp.data.pagination?.total,
  //       });
  //     });
  // };

  // getData = () => {
  //   axios
  //     .get(Constants.getUrls.Dashboard)
  //     .then((resp) => {
  //       //  console.log(resp.data.data.fund_details.bank_added);
  //       console.log(resp);
  //       this.setState(
  //         {
  //           live_contest: resp.data.data?.live_contest,
  //           bank_added: resp.data.data.fund_details.bank_added[0].sum,
  //           cash_bonus: resp.data.data.fund_details.cash_bonus[0].sum,
  //           winnings: resp.data.data.fund_details.winnings[0].sum,
  //           total_withdrawn: resp.data.data.fund_details.total_withdrawn[0].sum,
  //           users_cashbonus: resp.data.data.fund_details.users_cashbonus[0].sum,
  //           users_bankAdded: resp.data.data.fund_details.users_bankAdded[0].sum,
  //           referal_bonus: resp.data.data.fund_details.referal_bonus[0].sum,
  //           users_referalBonus:
  //             resp.data.data.fund_details.users_referalBonus[0].sum,
  //         },
  //         () => {
  //           const data = [
  //             {
  //               heading: "Bank Added Money",
  //               area: this.state.bank_added / 100,
  //               backgroundColor: "red",
  //             },
  //             { heading: "Cash Bonus", area: this.state.cash_bonus / 100 },
  //             {
  //               heading: "Winnings Money",
  //               area: (this.state.winnings / 100).toFixed(2),
  //             },
  //             {
  //               heading: "Withdrawal Money",
  //               area: (this.state.total_withdrawn / 100).toFixed(2),
  //             },
  //             {
  //               heading: "Left Cash Bonus ",
  //               area: (this.state.users_cashbonus / 100).toFixed(2),
  //             },
  //             {
  //               heading: "Left Bank Added ",
  //               area: (this.state.users_bankAdded / 100).toFixed(2),
  //             },
  //             {
  //               heading: "Distributed Referal Bonus ",
  //               area: this.state.referal_bonus / 100,
  //             },
  //             {
  //               heading: "Users Referal Bonus ",
  //               area: this.state.users_referalBonus / 100,
  //             },
  //           ];
  //           this.setState({
  //             data: data,
  //           });
  //         }
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  currency = (x) => {
    // console.log(x)
    let x1 =
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 3,
    }).format(x);

    return x1

  };

  // getContest = () => {
  //   if (init() === "success") {
  //     axios
  //       .get(
  //         Constants.getUrls.getAllContest +
  //           `?contest_creater=admins` +
  //           `&limit=${this.state.limit}` +
  //           `&page=${this.state.page}`
  //       )
  //       // axios.get('http://localhost:8084/getContests')
  //       .then((resp) => {
  //         console.log(resp);
  //         this.setState(
  //           {
  //             getAllContest: resp.data.Data,
  //             limit: resp.data.pagination?.limit,
  //             nextPage: resp.data.pagination.nextpage,
  //             page: resp.data.pagination.page,
  //             prevPage: resp.data.pagination.prevpage,
  //             totaldocs: resp.data.pagination.totaldocs,
  //             totalpage: resp.data.pagination.totalpage,
  //           },
  //           () => {
  //             // console.log(this.state.totaldocs);
  //           }
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };
  // handleSelect = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       if (init() === "success") {
  //         axios
  //           .get(
  //             Constants.getUrls.getAllContest +
  //               `?contest_creater=admins` +
  //               `&limit=${this.state.limit}` +
  //               `&page=${this.state.page}`
  //           )
  //           .then((resp) => {
  //             console.log(resp);
  //             this.setState({
  //               getAllContest: resp.data.Data,
  //               limit: resp.data.pagination.limit,
  //               nextPage: resp.data.pagination.nextpage,
  //               page: resp.data.pagination.page,
  //               prevPage: resp.data.pagination.prevpage,
  //               totaldocs: resp.data.pagination.totaldocs,
  //               totalpage: resp.data.pagination.totalpage,
  //             });
  //           });
  //       }
  //     }
  //   );
  // };

  // handleNext = () => {
  //   if (init() === "success") {
  //     axios
  //       .get(
  //         Constants.getUrls.getAllContest +
  //           `?contest_creater=admins` +
  //           `&limit=${this.state.limit}` +
  //           `&page=${this.state.page + 1}`
  //       )
  //       // axios.get('http://localhost:8084/getContests')
  //       .then((resp) => {
  //         // console.log(this.state.getAllContest,this.state.page);
  //         this.setState({
  //           getAllContest: resp.data.Data,
  //           // limit: resp.data.pagination?.limit,
  //           // nextPage: resp.data.pagination.nextpage,
  //           // page: resp.data.pagination.page,
  //           // prevPage: resp.data.pagination.prevpage,
  //           // totaldocs: resp.data.pagination.totaldocs,
  //           // totalpage: resp.data.pagination.totalpage,
  //         });
  //       });
  //   }
  // };

  // handlePrev = () => {
  //   if (init() === "success") {
  //     axios
  //       .get(
  //         Constants.getUrls.getAllContest +
  //           `?contest_creater=admins` +
  //           `&limit=${this.state.limit}` +
  //           `&page=${this.state.page - 1}`
  //       )
  //       // axios.get('http://localhost:8084/getContests')
  //       .then((resp) => {
  //         console.log(resp);
  //         this.setState({
  //           getAllContest: resp.data.Data,
  //           limit: resp.data.pagination.limit,
  //           nextPage: resp.data.pagination.nextpage,
  //           page: resp.data.pagination.page,
  //           prevPage: resp.data.pagination.prevpage,
  //           totaldocs: resp.data.pagination.totaldocs,
  //           totalpage: resp.data.pagination.totalpage,
  //         });
  //       });
  //   }
  // };

  // handleChange = (event, value) => {
  //   this.setState(
  //     {
  //       page: value,
  //     },
  //     () => {
  //       this.getContest();
  //     }
  //   );
  // };
  render() {
    const { data: chartData } = this.state;

    return (
      <>
        <Container maxWidth={"xl"}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">Dashboard</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "30px" }}>
            {/* <CardBox
              name={"CPU USAGE"}
              icon={<ComputerOutlined size={"small"} />}
              value={this.state.cpu}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#53BF9D"}
              shadow={" 247, 220, 111"}
            /> */}
            {/* <CardBox
              name={"RAM LEFT"}
              icon={<ComputerOutlined size={"small"} />}
              value={this.state.memory}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#ECB390"}
              shadow={" 247, 220, 111"}
            /> */}
            <CardBox
              name={"Total Users"}
              icon={<PersonAddOutlined size={"small"} />}
              value={this.state.totalUsers}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#6c6cff"}
              shadow={" 247, 220, 111"}
            />
            {/* <CardBox
              name={"Active Users"}
              value={this.state.client_count}
              icon={<ArrowDownward style={{ fontSize: "30" }} />}
              color={"#52BE80"}
              shadow={" 82, 190, 128"}
            />
            <CardBox
              name={"Live Contest"}
              value={this.state.live_contest}
              icon={<Copyright style={{ fontSize: "30" }} />}
              color={"#FE7E6D"}
              shadow={"  241, 148, 138 "}
            /> */}
            
            {/* <CardBox
              name={"Total Commission "}
              value= {this.currency(this.state.dashboardData.total_commission_amount)}

              // value={new Intl.NumberFormat("en-IN", {
              //   style: "currency",
              //   currency: "INR",
              //   maximumSignificantDigits: 3,
              // }).format(this.state.dashboardData.total_commission_amount)}


              icon={<AccountBalanceIcon style={{ fontSize: "30" }} />}
              color={"#85C1E9"}
              shadow={"133, 193, 233"}
            /> */}
          </Grid>

          {/* <Grid>
            {" "}
            <Typography variant="h4" style={{ marginTop: "15px" }}>
              Financial Details
            </Typography>
          </Grid> */}
          {/* <Grid container spacing={2} style={{ marginTop: "30px" }}>
            <CardBox
              name={"Bank Added"}
              icon={<AccountBalance size={"small"} />}
              value={this.state.bank_added / 100}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#3AB4F2"}
              shadow={" 247, 220, 111"}
            />
            <CardBox
              name={"Cash Bonus"}
              icon={<AccountBalance size={"small"} />}
              value={this.state.cash_bonus / 100}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              // color={"#3CCF4E"}

              color={"#EF5B0C"}
              shadow={" 247, 220, 111"}
            />
            <CardBox
              name={"Winnings"}
              icon={<AccountBalance size={"small"} />}
              value={(this.state.winnings / 100).toFixed(2)}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#3CCF4E"}
              shadow={" 247, 220, 111"}
            />
            <CardBox
              name={"Withdrawal"}
              icon={<AccountBalance size={"small"} />}
              value={(this.state.total_withdrawn / 100).toFixed(2)}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#FFF80A"}
              shadow={" 247, 220, 111"}
            />
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <CardBox
                name={"Left Cash Bonus"}
                icon={<AccountBalance size={"small"} />}
                value={(this.state.users_cashbonus / 100).toFixed(2)}
                // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
                color={"rgb(38, 166, 154)"}
                shadow={" 247, 220, 111"}
              />

              <CardBox
                name={"Left Bank Added"}
                icon={<AccountBalance size={"small"} />}
                value={(this.state.users_bankAdded / 100).toFixed(2)}
                // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
                color={"rgb(236, 64, 122)"}
                shadow={" 247, 220, 111"}
              />
              <CardBox
                name={"Distributed Referal Bonus"}
                icon={<AccountBalance size={"small"} />}
                value={(this.state.referal_bonus / 100).toFixed(2)}
                // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
                color={"rgb(66, 165, 245)"}
                shadow={" 247, 220, 111"}
              />

              <CardBox
                name={"Users Referal Bonus"}
                icon={<AccountBalance size={"small"} />}
                value={(this.state.users_referalBonus / 100).toFixed(2)}
                // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
                color={"rgb(255, 112, 67)"}
                shadow={" 247, 220, 111"}
              />
            </Grid>
          </Grid> */}

          <br></br>
          <div>
            {/* <h1 style={{ marginLeft: "450px" }}>Pie-Chart Representation</h1> */}
            {/* <Chart data={chartData}> */}
              {/* <div>
                <PieSeries
                  valueField="area"
                  argumentField="heading"
                  innerRadius={0.6}
                />
              </div> */}

              {/* <Legend /> */}

              {/* <div >
         <Title
              
                text="Pie-Chart Representation"
              />
         </div> */}

              {/* <Animation /> */}

              {/* <EventTracker
              //  data={chartData}
              /> */}
              {/* <HoverState
              // data={chartData}
              /> */}
              {/* <Tooltip
              //  data={chartData}
              /> */}
            {/* </Chart> */}
          </div>
          <br></br>
         
          <Grid
            container
            spacing={2}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            {/* <Grid item xs={4}>
              <Typography variant="h5">Live Contest</Typography>
            </Grid> */}
            <Grid item xs={8}>
              <Grid container justify="flex-end">
                {/* <FormControl style={{ marginRight: "10px" }}>
                <TextField
                  name="search"
                  id="outlined-basic"
                  placeholder={"Search Contest"}
                  size="small"
                  variant="outlined"
                />
              </FormControl> */}
                {/* <FormControl>
                  <Link
                    to="/admin/addcontest"
                    style={{ textDecoration: "none", marginRight: "10px" }}
                  >
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.setState({ open: true });
                      }}
                    >
                      Add Contest
                    </Button>
                  </Link>
                </FormControl> */}
                {/* <FormControl>
                <Link
                  to={{
                    pathname: "/admin/getContestDelete/",
                  }}
                  style={{ textDecoration: "none", marginRight: "10px" }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  >
                    Show Delete Contest
                  </Button>
                </Link>
              </FormControl>
              <FormControl>
                <Link
                  to={{
                    pathname: "/admin/privatecontests/",
                  }}
                  style={{
                    textDecoration: "none",
                    marginRight: "10px",
                    color: "#53BF9D",
                  }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    // color="secondary"
                    style={{
                      color: "#fff",
                      backgroundColor: "rgb(83, 191, 157)",
                    }}
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  >
                    Show Private Contest
                  </Button>
                </Link>
              </FormControl> */}
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
          <Grid container justify="flex-end">
          <FormControl>
                <Link  to={{
                              pathname:
                              "/admin/getContestDelete/"
                            }} style={{ textDecoration: "none" , marginRight:'20px'}}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  >
                    Show Delete Contest 
                  </Button>
                </Link>
              </FormControl>
              </Grid>
        </Grid> */}
          </Grid>

          
        
          {/* <div style={{ marginTop: "30px" }}>
            <h3 style={{ borderBottom: "2px solid white", width: "8rem" }}>
              Recent invoices
            </h3>
            <p>
              last invoice created at :{" "}
              {this.state.invoices.length > 0
                ? new Date(this.state.invoices[0].createdAt).toDateString()
                : ""}
            </p>
            <Grid item sm={12}>
              <Paper elevation={1}>
                <TableContainer>
                  <Table size="small" stickyHeader aria-label="a dense table">
                    <TableRow
                      className="tableHead"
                      style={{
                        height: "60px",
                        backgroundColor: "#85C1E9",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Sr.No
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Contract No
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Buyer Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Seller Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Total Commission Amount
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Total Invoice Amount
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      {this.state.invoices.map((invoice, index) => (
                        <TableRow key={invoice._id} style={{ height: "70px" }}>
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">
                            {" "}
                            {invoice.contract_no}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {invoice.buyer.name}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {invoice.seller.name}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {this.currency(invoice.total_commission_amount)}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {this.currency(invoice.total_amount)}
                            {/* &#8377;{invoice.total_amount} */}
          {/* </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> */}
          {/* PAGINATION TABLE START */}

          {/* PAGINATION TABLE END */}
          {/* </TableContainer>
              </Paper>
            </Grid>
          </div> */}
        </Container>
      </>
    );
  }
}
