import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  MenuItem,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import "../../App.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { Component } from "react";
import fileDownload from "js-file-download";
import Constants from "../Variables/Constants";
import init from "../../helpers/WindowToken";
import { lastPageRecordCount } from "./Components/PaginationTool";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "../../App.css";

import { makeStyles } from "@material-ui/core";

const classes = makeStyles();

export default class Contact extends Component {
  state = {
    _id: "",
    allContact: [],
    search: "",
    name: "",
    email: "",
    mobile_no: "",
    subject: "",
    message: "",

    contact_status: "",
    repiled_message: "",

    limit: 10,
    page: 1,
    nextPage: null,
    prevPage: null,
    totalDocs: 0,
    totalPages: 1,

    rowId: "",
    visible: false,
    loading: false,
    downloadMessage: "",

    // notifyDelete: false,
    notifyReply: false,
    // delMsg: "",
    replyMsg: "",
    // delLoading: false,
    replyLoading: false,
    generate: true,
    downloadfile: "",
  };

  componentDidMount() {
    this.getContacts();
  }

  getContacts = () => {
    //   if ( init() === "success" ){
    axios
      .get(
        Constants.getUrls.getAllContacts +
          `?limit=${this.state.limit}` +
          `&page=${this.state.page}`
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            allContact: resp.data.data.docs,
            contact_status: resp.data.data.contact_status,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
          },
          () => {
            console.log(
              this.state.allContact,
              this.state.limit,
              this.state.page
            );
          }
        );
        // this.handleStatusUpdate();
        this.getCsvPath();
      });
    //   }
  };

  handleSelect = (e) => {
    console.log(e);
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (init() === "success") {
          axios
            .get(
              Constants.getUrls.getAllContacts +
                `?limit=${this.state.limit}` +
                `&page=${1}`
            )
            .then((resp) => {
              console.log(resp);
              this.setState({
                allContact: resp.data.data.docs,
                contact_status: resp.data.data.contact_status,
                limit: resp.data.data.limit,
                nextPage: resp.data.data.nextPage,
                page: resp.data.data.page,
                prevPage: resp.data.data.prevPage,
                totalDocs: resp.data.data.totalDocs,
                totalPages: resp.data.data.totalPages,
              });
            });
        }
      }
    );
  };

  handleNext = () => {
    console.log(this.state.limit, this.state.page);
    if (init() === "success") {
      axios
        .get(
          Constants.getUrls.getAllContacts +
            `?limit=${this.state.limit}` +
            `&page=${this.state.page + 1}`
        )

        .then((resp) => {
          console.log(this.state.allContact, this.state.page);
          this.setState({
            allContact: resp.data.data.docs,
            contact_status: resp.data.data.contact_status,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
          });
        });
    }
  };

  handlePrev = () => {
    if (this.state.page > 1) {
      if (init() === "success") {
        axios
          .get(
            Constants.getUrls.getAllContacts +
              `?limit=${this.state.limit}` +
              `&page=${this.state.page - 1}`
          )

          .then((resp) => {
            console.log(resp);
            this.setState({
              allContact: resp.data.data.docs,
              contact_status: resp.data.data.contact_status,
              limit: resp.data.data.limit,
              nextPage: resp.data.data.nextPage,
              page: resp.data.data.page,
              prevPage: resp.data.data.prevPage,
              totalDocs: resp.data.data.totalDocs,
              totalPages: resp.data.data.totalPages,
            });
          });
      }
    }
  };

  handleStatusUpdate = () => {
    let payload = {
      _id: this.state._id,
      contact_status: this.state.contact_status,
    };
    console.log(payload);
    if (init() == "success") {
      axios
        .put(
          Constants.putUrls.UpdateContactStatus + "/" + this.state._id,
          payload
        )
        .then((res) => {
          console.log(res);
          this.setState({
            loading: false,
            notification: true,
            message: res.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 2000);
        });
    }
  };

  //  search start
  handleChange = (event, value) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.getContacts();
      }
    );
  };
  // search ends

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  // delete starts
  handleModal = (contact) => {
    this.setState({
      rowId: contact._id,
      visible: true,
    });
  };

  handleChangeEditor = (e) => {
    // console.log(e.target.value);

    console.log(this.state.repiled_message.length);
    if (this.state.repiled_message.length >= "") {
      this.setState(
        {
          repiled_message: e.target.value,
        },
        () => {
          console.log(this.state.repiled_message);
        }
      );
    }
  };

  handleReply = () => {
    this.setState({
      replyLoading: true,
    });
    let Payload = {
      _id: this.state.rowId,
      repiled_message: this.state.repiled_message,
    };

    if (init() == "success") {
      axios
        .put(Constants.putUrls.ReplyToContact + "/" + this.state.rowId, Payload)
        .then((res) => {
          console.log(res);
          this.setState({
            replyToUser: true,
            replyLoading: false,
            replyMsg: res.data.message,
            repiled_message: "",
          });

          setTimeout(() => {
            this.setState({ replyToUser: false, visible: false }, () => {
              this.getContacts();
            });
          }, 2000);
        });
    }
  };

  getCsvPath = () => {
    // if (init() === "success") {
    axios.get(Constants.getUrls.getContactExcel).then((resp) => {
      console.log(resp.data.path);
      this.setState({
        downloadfile: resp.data.path,
        generate: true,
      });
    });
    // .catch((err) => {
    //   console.log(err);
    // });
    // }
  };

  // handleDelete = () => {
  //     this.setState({
  //       delLoading: true
  //     })
  //     let payload = {
  //       _id: this.state.rowId
  //     }

  //     if (init() == "success") {
  //       axios.delete(Constants.postUrls.deleteContacts + this.state.rowId).then((res) => {
  //         console.log(res);
  //         this.setState({
  //           deleteContact: true,
  //           delLoading: false,
  //           delMsg:res.data.message
  //         });
  //         setTimeout(() => {
  //           this.setState({ deleteContact: false, visible: false }, () => {
  //             this.getContacts();
  //           });
  //         }, 2000);
  //       });
  //     }
  //   };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container spcaing={2}>
          <Grid item sm={6}>
            <Typography variant="h4"> Contact US </Typography>
          </Grid>
          <Grid container justify="flex-end">
            {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search Contact"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                                />
                        </FormControl> */}

            {/* <Link
                                to="/admin/addcontact"
                                style={{ textDecoration: "none"}}
                                >
                                    <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={()=>{
                                        this.setState({ open: true})
                                    }}
                                    >
                                        Create Contact
                                    </Button>
                                </Link> */}
            <Button color="primary" variant="contained" size="medium">
              {this.state.generate === true ? (
                <a
                  href={`https://core.prglobalresources.com${this.state.downloadfile}`}
                  download
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download Excel
                </a>
              ) : null}
            </Button>
          </Grid>
        </Grid>

        <Grid sm={12} style={{ marginTop: "2rem" }}>
          <Paper elevetion={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>SR.NO</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Contact Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.allContact?.length > 0 ? (
                    this.state.allContact?.map((contact, index) => (
                      <TableRow>
                        <TableCell>
                          {" "}
                          {Number((this.state.page - 1) * this.state.limit) +
                            Number(index + 1)}{" "}
                        </TableCell>
                        {/* <TableCell> {index + 1} </TableCell> */}
                        <TableCell> {contact.name} </TableCell>
                        <TableCell> {contact.email}</TableCell>
                        <TableCell> {contact.mobile_no}</TableCell>
                        <TableCell> {contact.subject}</TableCell>
                        <TableCell> {contact.message}</TableCell>
                        <TableCell>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              style={{
                                marginLeft: "15px",
                              }}
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              status
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              id="outlined-basic"
                              variant="outlined"
                              value={contact.contact_status}
                              inputProps={{
                                name: "contact_status",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState(
                                    {
                                      _id: contact._id,
                                      contact_status: e.target.value,
                                      [e.target.name]: e.target.value,
                                    },
                                    () => {
                                      console.log(contact.contact_status);
                                      this.handleStatusUpdate();
                                    }
                                  );
                                },
                                required: true,
                              }}
                            >
                              <MenuItem value="generated">Generated</MenuItem>
                              <MenuItem value="replied">Replied</MenuItem>
                              <MenuItem value="seen">Seen</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell>
                          {/* <Link
                                                to={{
                                                pathname: "" + contact._id,
                                                state: { data: contact },
                                                }}
                                                style={{textDecoration:"none"}}
                                             > */}
                          <Button
                            variant={"outlined"}
                            color="default"
                            style={{ marginRight: 4, marginBottom: "8px" }}
                            size={"small"}
                            onClick={() => {
                              this.handleModal(contact);
                            }}
                          >
                            Reply
                          </Button>
                          {/* </Link> */}
                          {/* <Button
                                                size={"small"}
                                                variant={"outlined"}
                                                color="secondary"
                                                onClick={() => {
                                                   this.handleModal(contact)
                                                }}
                                                 >
                                                Delete
                                            </Button> */}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>NO DATA FOUND</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TableRow
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div>
                  <p style={{ marginTop: "1.2rem" }}>
                    Showing{" "}
                    {Number((this.state.page - 1) * this.state.limit) + 1} to{" "}
                    {lastPageRecordCount(
                      this.state.totalDocs,
                      this.state.page,
                      this.state.limit
                    )}{" "}
                    of {this.state.totalDocs} Entries
                  </p>
                </div>

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "200px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-12px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      value={this.state.limit}
                      onChange={this.handleSelect}
                      inputProps={{
                        name: "limit",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Select>
                  </FormControl>
                </div>

                {/* Rows PerPage END */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "290px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mx-5"
                    style={{ margin: "10px" }}
                    disabled={this.state.prevPage === null}
                    onClick={this.handlePrev}
                    // Hidden
                  >
                    Previous
                  </Button>{" "}
                  <Pagination
                    count={this.state.totalPages}
                    shape="rounded"
                    page={this.state.page}
                    onChange={this.handleChange}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className="mx-5"
                    disabled={this.state.nextPage === null}
                    // style={{ margin: "10px", marginLeft: "1rem" }}
                    onClick={this.handleNext}
                  >
                    Next
                  </Button>
                </div>
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        {/* <Modal
          visible={this.state.visible}
          onClickAway={() => this.setState({ visible: false })}
          width="450px"
          height="80px"
          effect="fadeInUp"
        >
          <Row>
            <Col xs={12} xm={12} md={12}>
              <Card>
                <CardBody>
                  <div className="modal">
                    <h3 classname="changeColor" style={{ color: "black" }}>
                      {" "}
                      Are you sure you want to Delete??
                    </h3>

                    {this.state.deleteContact ? (
                      <Snackbar
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "bottom",
                        }}
                        open={true}
                        autoHideDuration={5000}
                        color={"s"}
                        message="deleted Successfully"
                      ></Snackbar>
                    ) : null}

                    <Row>
                      <Col xs={12} xm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="secondary"
                          variant="contained"
                          style={{ margin: "20px" }}
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.deleteContact
                            ? "Deleting Contact"
                            : "Yes"}
                        </Button>
                        <Button
                          className=""
                          variant="contained"
                          color="primary"
                          style={{ margin: "20px", marginLeft: "4px" }}
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                        >
                          no
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal> */}

        <Modal
          visible={this.state.visible}
          onClickAway={() => this.setState({ visible: false })}
          width="800px"
          height="350px"
          effect="fadeInUp"
        >
          <Row>
            <Col xs={12} xm={12} md={12}>
              <Card>
                <CardBody>
                  <div className="modal">
                    <h3 classname="changeColor" style={{ color: "black" }}>
                      Reply to User
                    </h3>

                    {this.state.replyToUser ? (
                      <Snackbar
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "bottom",
                        }}
                        open={true}
                        autoHideDuration={3000}
                        color={"s"}
                        message="Mail sent sucessfully"
                      ></Snackbar>
                    ) : null}

                    <Grid
                      container
                      sm={12}
                      spacing={2}
                      style={{ marginLeft: "5px", paddingTop: "10px" }}
                    >
                      <Grid item sm={12} xs={6}>
                        <div
                          style={{
                            color: "black",
                            marginTop: "0.2rem",
                            marginLeft: "0.3rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          {/* <SunEditor
                            // width="120%"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                // ['imageGallery'], // You must add the "imageGalleryUrl".
                                // ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                // ['save', 'template'],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "180px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            name="repiled_message"
                            value={this.state.repiled_message}
                            onChange={this.handleChangeEditor.bind(this)}
                          /> */}
                          <textarea
                            name="repiled_message"
                            cols={90}
                            rows={10}
                            value={this.state.repiled_message}
                            onChange={this.handleChangeEditor}
                            style={{
                              // marginLeft: "0.7rem",
                              // minHeight: "180px",
                              padding: "0.6rem",
                              fontSize: "1rem",
                              fontFamily: "sans-serif",
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Row>
                      <Col xs={12} xm={12} md={4}>
                        <br />

                        <Button
                          className="mx-5 MuiButton-contained.Mui-disabled"
                          color="primary"
                          variant="contained"
                          style={{ margin: "10px" }}
                          disabled={this.state.repiled_message === ""}
                          onClick={() => {
                            this.handleReply(this.state.rowId);
                          }}
                        >
                          {this.state.replyToUser
                            ? "Sending.... Reply"
                            : "Send Reply"}
                          {/* Reply To User */}
                        </Button>

                        <Button
                          className=""
                          variant="contained"
                          color="secondary"
                          style={{ margin: "10px", marginLeft: "4px" }}
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                        >
                          Back
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal>
      </Container>
    );
  }
}
