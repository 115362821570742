import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
  //   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { makeStyles } from "@material-ui/core";
import { Category } from "@material-ui/icons";

const classes = makeStyles();

export default class AddAgrisubcategory extends Component {
  state = {
    title: "",
    sequence: "",
    getAllCategory: [],
    agri_category_id: "",
    loading: false,
    notification: false,
    message: "",
  };

  componentDidMount() {
    if (init() === "success") {
      axios
        .get(Constants.getUrls.getAllArgiCategory + `?limit=${100}`)
        .then((resp) => {
          console.log(resp);
          this.setState({
            getAllCategory: resp.data.data.docs,
          });
        });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let subCategoryLoad = {
      title: this.state.title,
      sequence: this.state.sequence,
      agri_category_id: this.state.agri_category_id,
    };
    // console.log( "subCategoryLoad", subCategoryLoad)
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios
        .post(Constants.postUrls.AddAgriSubCategory, subCategoryLoad)
        .then((resp) => {
          // console.log(resp)
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 3000);
        })
        .catch((error) => {
          // console.log(error.resp);
          this.setState({
            notification: true,
            redirect: false,
            message: error.response.data.message,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 3000);
        });
    }
  };
  handleSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/Agrisubcategory" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5"> Create SubCategory </Typography>
            </Grid>
          </Grid>

          {/* After Getting api add Snackbar notification here  */}
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={true}
              color={"s"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}

          <div
            style={{
              background: "#5b5757",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              border: "0",
              display: "flex",
              position: "relative",
              fontSize: ".875rem",
              minWidth: "0",
              wordWrap: " break-word",
              boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
              marginTop: "30px",
              borderRadius: "6px",
              marginBottom: "30px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>

            <form onSubmit={this.handleSubmit}>
              <Grid container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <FormControl
                    // style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      style={{
                        marginLeft: "15px",
                      }}
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Category
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      value={this.state.agri_category_id}
                      inputProps={{
                        name: "agri_category_id",
                        id: "simple-select",
                        onChange: this.handleSelect,
                        required: true,
                      }}
                    >
                      {this.state.getAllCategory.map((category, i) => (
                        <MenuItem key={i} value={category._id}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Title"
                    name="title"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                {/* <Grid item sm={4}>
                  <TextField
                    type="Number"
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Sequence Number"
                    name="sequence"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid> */}

                {/* <div style={{ color: "black", marginLeft: "8px", width: "99%"}}>
                           <label style={{color: "white"}}> Description</label>
                            <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log({ event, editor, data });
                                this.setState({
                                  description: data,
                                });
                              }}
                              data={this.state.description}
                            />
                          </div> */}
              </Grid>

              <br />
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    width: "15%",
                    marginTop: "35px",
                  }}
                >
                  {this.state.loading ? "Adding.." : "Add SubCategory"}
                </Button>
                <Link
                  to={{
                    pathname: "/Agrisubcategory",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </Container>
      );
    }
  }
}
