import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import "../../App.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";

import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../Variables/Constants";
import init from "../../helpers/WindowToken";
import "../../App.css";
import { lastPageRecordCount } from "./Components/PaginationTool";

export default class HomeScreen extends Component {
  state = {
    allHomeData: [],
    search: "",
    title: "",
    image: "",
    description: "",
    created_by: "",
    name: "",

    limit: 10,
    page: 1,
    nextPage: null,
    prevPage: null,
    totalDocs: 0,
    totalPages: 1,

    rowId: "",
    visible: false,
    loading: false,
    notifyDelete: false,
    delMsg: "",
    delLoading: false,
  };

  componentDidMount() {
    this.getHome();
  }

  getHome = () => {
    //   if ( init() === "success" ){
    axios
      .get(
        Constants.getUrls.getHomeDetails +
          `?limit=${this.state.limit}` +
          `&page=${this.state.page}`
      )

      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            allHomeData: resp.data.data.docs,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
            // loading: true
          },
          () => {
            console.log(this.state.allHomeData);
          }
        );
      });
    //   }
  };

  handleSelect = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (init() === "success") {
          axios
            .get(
              Constants.getUrls.getHomeDetails +
                `?limit=${this.state.limit}` +
                `&page=${1}`
            )
            .then((resp) => {
              console.log(resp);
              this.setState({
                allHomeData: resp.data.data.docs,
                limit: resp.data.data.limit,
                nextPage: resp.data.data.nextPage,
                page: resp.data.data.page,
                prevPage: resp.data.data.prevPage,
                totalDocs: resp.data.data.totalDocs,
                totalPages: resp.data.data.totalPages,
              });
            });
        }
      }
    );
  };

  handleNext = () => {
    if (init() === "success") {
      axios
        .get(
          Constants.getUrls.getHomeDetails +
            `?limit=${this.state.limit}` +
            `&page=${this.state.page + 1}`
        )

        .then((resp) => {
          console.log(this.state.allHomeData, this.state.page);
          this.setState({
            allHomeData: resp.data.data.docs,
            limit: resp.data.data.limit,
            nextPage: resp.data.data.nextPage,
            page: resp.data.data.page,
            prevPage: resp.data.data.prevPage,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages,
          });
        });
    }
  };

  handlePrev = () => {
    if (this.state.page > 1) {
      if (init() === "success") {
        axios
          .get(
            Constants.getUrls.getHomeDetails +
              `?limit=${this.state.limit}` +
              `&page=${this.state.page - 1}`
          )

          .then((resp) => {
            console.log(resp);
            this.setState({
              allHomeData: resp.data.data.docs,
              limit: resp.data.data.limit,
              nextPage: resp.data.data.nextpage,
              page: resp.data.data.page,
              prevPage: resp.data.data.prevpage,
              totalDocs: resp.data.data.totalDocs,
              totalPages: resp.data.data.totalPages,
            });
          });
      }
    }
  };

  handleChange = (event, value) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.getHome();
      }
    );
  };
  // search ends

  // delete starts
  handleModal = (home) => {
    this.setState({
      rowId: home._id,
      visible: true,
    });
  };

  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
    let payload = {
      _id: this.state.rowId,
    };

    if (init() == "success") {
      // this.setState({ delLoading: true });
      axios
        .delete(Constants.postUrls.deleteHomeDetails + this.state.rowId)
        .then((res) => {
          console.log(res);
          this.setState({
            deleteHome: true,
            delLoading: false,
            delMsg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ deleteHome: false, visible: false }, () => {
              this.getHome();
            });
          }, 2000);
        });
    }
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container spcaing={2}>
          <Grid item sm={6}>
            <Typography variant="h4">Paper Home Screen</Typography>
          </Grid>
          <Grid container justify="flex-end">
            {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search Testimonial"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                                />
                        </FormControl> */}

            <Link
              to="/admin/addhomedetails"
              style={{ textDecoration: "none", marginBottom: "1rem" }}
            >
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                Add Home Details
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Grid sm={12}>
          <Paper elevetion={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Image</TableCell>

                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.allHomeData?.length > 0 ? (
                    this.state.allHomeData.map((home, index) => (
                      <TableRow>
                        <TableCell>
                          {" "}
                          {Number((this.state.page - 1) * this.state.limit) +
                            Number(index + 1)}{" "}
                        </TableCell>
                        <TableCell> {home.title} </TableCell>
                        <TableCell> {renderHTML(home.description)}</TableCell>
                        <TableCell>
                          <img
                            src={
                              Constants.imgDomain.imgMainDomain +
                              `${home?.image}`
                            }
                            alt="pic"
                            width="100vw"
                            height="100vh"
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Link
                            to={{
                              pathname: "/admin/updatehomedetails/" + home._id,
                              state: { data: home },
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant={"outlined"}
                              color="default"
                              style={{ marginRight: 4, marginBottom: 6 }}
                              size={"small"}
                            >
                              Update
                            </Button>
                          </Link>
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            color="secondary"
                            onClick={() => {
                              this.handleModal(home);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>NO DATA FOUND</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TableRow
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div>
                  <p>
                    Showing {this.state.page} to{" "}
                    {lastPageRecordCount(
                      this.state.totalDocs,
                      this.state.page,
                      this.state.limit
                    )}{" "}
                    of {this.state.totalDocs} Entries
                  </p>
                </div>

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "200px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-12px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      value={this.state.limit}
                      onChange={this.handleSelect}
                      inputProps={{
                        name: "limit",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Select>
                  </FormControl>
                </div>

                {/* Rows PerPage END */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "290px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mx-5"
                    style={{ margin: "10px" }}
                    disabled={this.state.prevPage === null}
                    onClick={this.handlePrev}
                    // Hidden
                  >
                    Previous
                  </Button>{" "}
                  <Pagination
                    count={this.state.totalPages}
                    shape="rounded"
                    page={this.state.page}
                    onChange={this.handleChange}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className="mx-5"
                    disabled={this.state.nextPage === null}
                    style={{ margin: "10px" }}
                    onClick={this.handleNext}
                  >
                    Next
                  </Button>
                </div>
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>
        <Modal
          visible={this.state.visible}
          onClickAway={() => this.setState({ visible: false })}
          width="450px"
          height="80px"
          effect="fadeInUp"
        >
          <Row>
            <Col xs={12} xm={12} md={12}>
              <Card>
                <CardBody>
                  <div className="modal">
                    <h3 classname="changeColor" style={{ color: "black" }}>
                      {" "}
                      Are you sure you want to Delete??
                    </h3>

                    {this.state.deleteHome ? (
                      <Snackbar
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "bottom",
                        }}
                        open={true}
                        autoHideDuration={5000}
                        color={"s"}
                        message="deleted Successfully"
                      ></Snackbar>
                    ) : null}

                    <Row>
                      <Col xs={12} xm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="secondary"
                          variant="contained"
                          style={{ margin: "20px" }}
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.deleteHome
                            ? "Deleting Home Details"
                            : "Yes"}
                        </Button>
                        <Button
                          className=""
                          variant="contained"
                          color="primary"
                          style={{ margin: "20px", marginLeft: "4px" }}
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                        >
                          no
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal>
      </Container>
    );
  }
}
