import { Grid } from "@material-ui/core";
import { Dashboard } from "@material-ui/icons";
import React, { Component } from "react";
// import "";
// import "../../App.css";
import "../../App.css";
import { ExpandLess, ExpandMore, ExpandMoreOutlined } from "@material-ui/icons";
import { Redirect, Route, Switch } from "react-router-dom";

import routes from "../../routes";

import AuthLoading from "../Auth/AuthLoading";
import MainAppbar from "./Layout/MainAppbar";
import Sidebar from "./Layout/Sidebar";
import { DashboardOutlined, MenuOpen, Menu } from "@material-ui/icons";

// pr paper section start

import AddCategory from "../../AddViews/AddCategory";
import UpdateCategory from "../../UpdateViews/UpdateCategory";

import AddSubCategory from "../../AddViews/AddSubCategory";
import UpdateSubCategory from "../../UpdateViews/UpdateSubCategory";

import AddProduct from "../../AddViews/AddProduct";
import UpdateProduct from "../../UpdateViews/UpdateProduct";

import AddCertificate from "../../AddViews/AddCertificate";
import UpdateCertificate from "../../UpdateViews/UpdateCertificate";

import AddHomeScreen from "../../AddViews/AddHomeScreen";
import UpdateHomeScreen from "../../UpdateViews/UpdateHomeScreen";

import AddServices from "../../AddViews/AddServices";
import UpdateService from "../../UpdateViews/UpdateService";

import AddwhoWeAre from "../../AddViews/AddwhoWeAre";
import UpdatewhoWeAre from "../../UpdateViews/UpdatewhoWeAre";

import AddWebContact from "../../AddViews/AddWebContact";
import UpdateWebContact from "../../UpdateViews/UpdateWebContact";

import AddEmailSignature from "../../AddViews/AddEmailSignature";
import updateEmailSignature from "../../UpdateViews/UpdateEmailSignature";

//pr paper section end

//pr agriculture section start

import AddAgricategory from "../../AddViews/AddAgricategory";
import UpdateAgricategory from "../../UpdateViews/UpdateAgricategory";

import AddAgrisubcategory from "../../AddViews/AddAgrisubcategory";
import UpdateAgrisubcategory from "../../UpdateViews/UpdateAgrisubcategory";

import AddAgriProducts from "../../AddViews/AddAgriProducts";
import updateAgriProducts from "../../UpdateViews/UpdateAgriProducts";

import AddAgriHome from "../../AddViews/AddAgriHome";
import UpdateAgriHome from "../../UpdateViews/UpdateAgriHome";

import AddAgriServices from "../../AddViews/AddAgriServices";
import UpdateAgriServices from "../../UpdateViews/UpdateAgriServices";

import AddAgriCertificate from "../../AddViews/AddAgriCertificate";
import UpdateAgriCertificate from "../../UpdateViews/UpdateAgriCertificate";

import axios from "axios";
import Constants from "../Variables/Constants";

export default class Main extends Component {
  state = {
    loading: true,
    authenticated: false,
    role: "agent",
    profileData: [],
    // sidebarToggle: true,
  };

  // handleDrawerOpen = () => {
  //   this.setState({ sidebarToggle: !this.state.sidebarToggle });
  // };

  // handleDrawerClose = () => {
  //   this.setState({ open: false });
  // };

  checkAuth = (status) => {
    if (status) {
      // console.log(status)
      this.setState({ loading: false, authenticated: true });
      this.getProfile();
    } else {
      // this.props.history.push("/login");
      this.setState({ loading: false, authenticated: false });
    }
  };

  getProfile = () => {
    axios.get(Constants.getUrls.getAdminProfile).then((res) => {
      // console.log(res.data);
      if (res.data.status === "success") {
        this.setState({
          profileData: res.data.user,
        });
      }
      // console.log(this.state.profileData.role);
    });
  };

  render() {
    return (
      <div>
        {this.state.loading ? <AuthLoading checkAuth={this.checkAuth} /> : null}
        {this.state.authenticated ? (
          <>
            <MainAppbar />

            <Grid container className="main">
              <Grid
                item
                style={{
                  position: "fixed",
                  width: "16.666667%",
                  marginTop: "70px",
                }}
                className="sidebar"
              >
                <Sidebar />
                {/* {this.state.sidebarToggle ? <Sidebar /> : null}
                {this.state.sidebarToggle && <Sidebar  />} */}
              </Grid>

              <Grid item xs={2} style={{ margin: "20px 0" }}></Grid>

              <Grid
                item
                xs={10}
                spacing={1}
                style={{
                  margin: "100px 0",
                  //   marginLeft: this.state.sidebarToggle ? "0px" : "100px",
                }}
                // className={this.state.sidebarToggle ? '.moved' : 'effect'}
              >
                {/* <button
                  onClick={this.handleDrawerOpen}
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    marginLeft: "20px",
                  }}
                  className="e-btn e-info"
                >
                  {" "}
                  
                  {this.state.sidebarToggle ? <MenuOpen  className="sidebar1"/> : <Menu />}
                </button> */}

                <Switch>
                  {/* <Switch defau> */}
                  {/* {routes.map((route, index) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      // component={route.component} -- NOT WORKING
                      render={() => <route.component title={route.title} />}
                      key={index}
                    />
                  ))} */}

                  {routes.Dashboard.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {/* {routes.Home.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))} */}

                  {/* {routes.Products.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))} */}

                  {/* {routes.Category.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))} */}

                  {/* {routes.SubCategory.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))} */}

                  {/* {routes.Products.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))} */}

                  {/* {routes.Contact.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))} */}
                  {/* 
                  {routes.Enquiry.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))}

                  {routes.Certificate.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))}

                  {routes.Services.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))} */}

                  {routes.PR_Papers.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))}

                  {routes.PR_agri.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))}

                  {/* {routes.Services.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))} */}

                  {/* {routes.Certificate.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      render={() => <route.component title={route.title} />}
                    />
                  ))} */}

                  {/* {routes.Contact.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))} */}

                  {/* pr paper  add views start */}

                  <Route path="/admin/addcategory" component={AddCategory} />
                  <Route
                    path="/admin/updatecategorys/"
                    component={UpdateCategory}
                  />

                  <Route
                    path="/admin/addsubcategory"
                    component={AddSubCategory}
                  />
                  <Route
                    path="/admin/updatesubcategorys/"
                    component={UpdateSubCategory}
                  />

                  <Route path="/admin/addproducts" component={AddProduct} />
                  <Route
                    path="/admin/updateproducts/"
                    component={UpdateProduct}
                  />

                  <Route
                    path="/admin/addcertificates"
                    component={AddCertificate}
                  />
                  <Route
                    path="/admin/updatecertificate/"
                    component={UpdateCertificate}
                  />

                  <Route
                    path="/admin/addhomedetails"
                    component={AddHomeScreen}
                  />

                  <Route
                    path="/admin/updatehomedetails/"
                    component={UpdateHomeScreen}
                  />

                  <Route path="/admin/addservice" component={AddServices} />
                  <Route
                    path="/admin/updateservices/"
                    component={UpdateService}
                  />

                  <Route path="/admin/addwhoweare" component={AddwhoWeAre} />
                  <Route
                    path="/admin/updatewhoweare/"
                    component={UpdatewhoWeAre}
                  />

                  <Route
                    path="/admin/addwebcontact"
                    component={AddWebContact}
                  />

                  <Route
                    path="/admin/updatewebcontact/"
                    component={UpdateWebContact}
                  />

                  {/* pr paper add view end */}

                  <Route
                    path="/admin/addagricategory"
                    component={AddAgricategory}
                  />

                  <Route
                    path="/admin/updateAgricategory/"
                    component={UpdateAgricategory}
                  />

                  <Route
                    path="/admin/addAgriSubcategory"
                    component={AddAgrisubcategory}
                  />

                  <Route
                    path="/admin/updateAgrisubcategory/"
                    component={UpdateAgrisubcategory}
                  />

                  <Route
                    path="/admin/addAgriproducts"
                    component={AddAgriProducts}
                  />
                  <Route
                    path="/admin/updateAgriproducts/"
                    component={updateAgriProducts}
                  />

                  <Route path="/admin/addagrihome" component={AddAgriHome} />
                  <Route
                    path="/admin/updateagrihome/"
                    component={UpdateAgriHome}
                  />

                  <Route
                    path="/admin/addAgriservice"
                    component={AddAgriServices}
                  />

                  <Route
                    path="/admin/updateAgriservices/"
                    component={UpdateAgriServices}
                  />

                  <Route
                    path="/admin/addagricertificates"
                    component={AddAgriCertificate}
                  />

                  <Route
                    path="/admin/updateAgricertificate/"
                    component={UpdateAgriCertificate}
                  />

                  <Route
                    path="/admin/emailsignature"
                    component={AddEmailSignature}
                  />

                  <Route
                    path="/admin/updateEmailsignature/"
                    component={updateEmailSignature}
                  />

                  {/* {this.state.profileData.role !== "agent" && (
                    <Route
                      exact
                      path="/admin/addagentcopy"
                      component={AddAgentCopy}
                    />
                  )} */}

                  {/* <Route path="/admin/addagent" component={Addagents} /> */}
                  {/* {this.state.profileData.role !== "agent" && (
                    <Route exact path="/admin/addagent" component={Addagents} />
                  )} */}

                  {/* <Route path="/admin/updatestocks/" component={UpdateStocks} /> */}
                </Switch>
              </Grid>
            </Grid>
          </>
        ) : !this.state.loading ? (
          ""
        ) : // <Redirect to="/login" />
        null}
      </div>
    );
  }
}

// ) : !this.state.loading ? (
//   <Redirect to="/login" />
// ) : null}
