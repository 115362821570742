import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
  //   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { makeStyles } from "@material-ui/core";

const classes = makeStyles();

export default class AddAgriHome extends Component {
  state = {
    title: "",
    image: "",
    description: "",
    notification: false,
    redirect: false,
    loading: false,
  };

  handleChange(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        description: content,
      },
      () => {
        console.log(this.state.description);
      }
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let payload = new FormData();
    payload.append("title", this.state.title);
    payload.append("description", this.state.description);
    payload.append("image", this.state.image);

    console.log("payload", payload);
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios
        .post(Constants.postUrls.AddAgriHomeDetails, payload)
        .then((resp) => {
          console.log(resp);
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            notification: true,
            message: error.response.data.message,
          });
          // console.log(error.response.data.message);
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: false,
              loading: false,
            });
          }, 1500);
        });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    if (e.target.name === "image") {
      this.setState({
        image: e.target.files[0],
      });
    }
    console.log(this.state.image);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/agrihome" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5"> Create Home Details </Typography>
            </Grid>
          </Grid>

          {/* After Getting api add Snackbar notification here  */}
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={true}
              color={"s"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}

          <div
            style={{
              background: "#5b5757",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              border: "0",
              display: "flex",
              position: "relative",
              fontSize: ".875rem",
              minWidth: "0",
              wordWrap: " break-word",
              boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
              marginTop: "30px",
              borderRadius: "6px",
              marginBottom: "30px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>

            <form onSubmit={this.handleSubmit}>
              <Grid container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Title"
                    name="title"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    onChange={this.handleFile}
                    label="Image"
                    name="image"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                {/* <div
                  style={{
                    color: "black",
                    marginTop: "1rem",
                    marginLeft: "0.6rem",
                  }}
                >
                  <SunEditor
                    // width="120%"
                    setOptions={{
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize"],
                        ["paragraphStyle", "blockquote"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                        ],
                        ["fontColor", "hiliteColor"],
                        ["align", "list", "lineHeight"],
                        ["outdent", "indent"],

                        ["table", "horizontalRule", "link", "image", "video"],
                        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                        // ['imageGallery'], // You must add the "imageGalleryUrl".
                        ["fullScreen", "showBlocks", "codeView"],
                        ["preview", "print"],
                        ["removeFormat"],

                        ["save", "template"],
                        // '/', Line break
                      ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                      defaultTag: "div",
                      minHeight: "250px",
                      showPathLabel: false,
                      // font: sortedFontOptions
                    }}
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange.bind(this)}
                  />
                </div> */}
              </Grid>

              <br />
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    width: "15%",
                    marginTop: "35px",
                  }}
                >
                  {this.state.loading ? "Adding.." : "Add Home Details"}
                </Button>
                <Link
                  to={{
                    pathname: "/agrihome",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </Container>
      );
    }
  }
}
