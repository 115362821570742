import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Logo from "../../Assets/Stocks10.png";

import axios from "axios";
import { Link } from "react-router-dom";

import React, { Component } from "react";
import Constants from "../Variables/Constants";

export default class Login extends Component {
  state = {
    error: false,
    email: "",
    mobileNumber: "",
    password: "",
    role: "superadmin",
    radioValue: "emailRadio",
  };

  login = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let loginLoad = {
      email: this.state.email,
      mobile_no: this.state.mobileNumber,
      password: this.state.password,
      role: "superadmin",
    };
    // console.log(loginLoad);
    // return
    axios
      .post(Constants.postUrls.adminLogin, loginLoad)
      .then((resp) => {
        console.log(resp);
        localStorage.setItem("accessToken", resp.data.token);
        window.location.href = "/dashboard";

        this.setState({
          notification: true,
          loading: false,
        });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          this.setState({
            loading: false,
            message: "Invalid Credentials",
            error: true,
          });
          setTimeout(() => {
            this.setState({
              error: false,
            });
          }, 3000);
        }
      });
  };

  //condition for changing input types
  handleInputField = (e) => {
    //  if(e.target.value === "mobile_no" ){
    this.setState({
      email: "",
      mobileNumber: "",
      // password: "",
    });
    if (e.target.value === "emailRadio") {
      this.setState({
        //  showMobileFiled : e.target.value
        radioValue: e.target.value,
      });
    } else {
      this.setState({
        radioValue: e.target.value,
      });
    }
    console.log(e.target.value);
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div className="loginWrapper">
        <div
          style={{
            height: "100vh",
            backgroundColor: "#fafafa2e",
            opacity: "0.85",
          }}
        >
          {/* <img src={Logo}
            style={{
             marginTop: "75px",
             marginLeft: "50px",
              height: "100px",
              // width: "20px",
            }}
            /> */}
          {/* https://files.slack.com/files-pri/TNF0B8YSZ-F02ASNY85PG/image.png */}
          <div>
            <Container maxWidth="md">
              <Grid container>
                <Grid
                  item
                  sm={6}
                  style={{
                    position: "relative",
                    height: "80vh",
                    marginTop: "40px",
                  }}
                >
                  <Paper
                    className="loginCard"
                    elevation={15}
                    style={{ height: "70vh" }}
                  >
                    <Typography
                      variant="h4"
                      component="h2"
                      gutterBottom
                      style={{ color: "#64aa37" }}
                    >
                      PR GLOBAL
                    </Typography>
                    <Typography variant="h5" component="h1">
                      Log in Using Email or Mobile
                    </Typography>
                    <Typography
                      variant="body2"
                      component="h1"
                      gutterBottom
                      color="textSecondary"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      Continue to Admin dashboard
                    </Typography>
                    {/* <Typography
                  color="primary"
                  variant="body2"
                  style={{ alignSelf: "flex-end" , cursor:"pointer" }}
                >
                  Forgot password?
                </Typography> */}
                    <form
                      className="loginFormWrapper"
                      onSubmit={this.login}
                      style={{ marginTop: "1.5rem" }}
                    >
                      <FormControl style={{ marginBottom: "1rem" }}>
                        {/* <FormLabel id="demo-row-radio-buttons-group-label" style={{color:"white"}}>Sign In Using Email or Mobile</FormLabel> */}
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={this.handleInputField}
                          value={this.state.radioValue}
                        >
                          <FormControlLabel
                            value="emailRadio"
                            control={<Radio />}
                            label="Email"
                          />
                          <FormControlLabel
                            value="mobile_noRadio"
                            control={<Radio />}
                            label="Phone Number"
                          />
                        </RadioGroup>
                      </FormControl>

                      {this.state.radioValue === "emailRadio" ? (
                        <TextField
                          id="outlined-basic"
                          color="success"
                          label="E-mail id"
                          variant="outlined"
                          fullWidth
                          value={this.state.email}
                          name="email"
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value,
                              mobileNumber: "",
                            });
                          }}
                          style={{ marginBottom: "1rem" }}
                        />
                      ) : (
                        <TextField
                          id="Outlined Success"
                          color="success"
                          label="Registered Mobile Number"
                          variant="outlined"
                          fullWidth
                          name="mobileNumber"
                          value={this.state.mobileNumber}
                          onChange={(e) => {
                            this.setState({
                              [e.target.name]: e.target.value,
                              email: "",
                            });
                          }}
                          style={{ marginBottom: "1rem" }}
                        />
                      )}

                      <TextField
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        fullWidth
                        name="password"
                        type="password"
                        onChange={this.handleInput}
                      />

                      <Button
                        style={{
                          alignSelf: "flex-start",
                          backgroundColor: "#64aa37",
                          color: "#fff",
                          marginTop: "1rem",
                        }}
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {this.state.loading ? (
                          "Logging In..."
                        ) : this.state.error ? (
                          <span style={{ color: "#BD0600" }}>
                            Incorrect Credentials!!!
                          </span>
                        ) : (
                          " Log In"
                        )}
                      </Button>
                    </form>

                    <Grid
                      item
                      sm={12}
                      style={{ position: "relative", marginTop: "3rem" }}
                    >
                      {/* <Link to="/forgot" style={{ textDecoration: "none" }}>
                    <Typography
                      
                      variant="body2"
                      style={{
                        color: "#fff",
                        alignSelf: "right",
                        cursor: "pointer",
                        display: "inline",
                        // marginTop : "2rem"
                      }}
                    >
                      Forgot password?
                    </Typography>
                  </Link> */}
                      {/* <Link to="/change" style={{ textDecoration: "none" }}>
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{
                        alignSelf: "flex-end",
                        cursor: "pointer",
                        display: "inline",
                        marginLeft: "10px",
                      }}
                    >
                      Change password?
                    </Typography>
                  </Link> */}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
