import {
  AppBar,
  Button,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Typography,
  TextField,
  List,
  ListItemText,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import {
  ArrowDropDown,
  LockRounded,
  PersonAddOutlined,
  SettingsBrightness,
  WbSunnyTwoTone,
} from "@material-ui/icons";
import {
  // PersonAddOutlined,
  ArrowDownward,
  Copyright,
  ComputerOutlined,
  AccountBalance,
} from "@material-ui/icons";
import CardBox from "../../Main/Card";
import LogoPR from "../../../Assets/logo_pr.png";

import Modal from "react-awesome-modal";
import axios from "axios";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React, { Component } from "react";
import Constants from "../../Variables/Constants";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import profile from "../../../Assets/profile.jpg";
import init from "../../../helpers/WindowToken";
// import io from "socket.io-client";
let authToken = localStorage.getItem("accessToken");
// const socket = io.connect("https://core.stocks10.com");
// const socket = io.connect("http://localhost:8084");

export default class MainAppbar extends Component {
  state = {
    theme: localStorage.getItem("theme"),
    showMenu: false,
    anchorEl: null,
    profileData: {},
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    visibleProfile: false,
    name: "",
    email: "",
    mobile_no: "",
    cpu: "",
    memory: "",
  };

  componentDidMount() {
    this.getProfile();
    // this.core();
  }

  // core = () => {
  //   socket.on("core_server", (data) => {
  //     this.setState(
  //       {
  //         cpu: data.cpu,
  //         memory: data.memory,
  //       },
  //       () => {
  //         // console.log(this.state.cpu);
  //       }
  //     );
  //   });
  // };

  componentWillUnmount() {
    // console.log("socket disconnected");
    // socket.disconnect();
  }
  getProfile = () => {
    // if( init() === "success"){
    axios.get(Constants.getUrls.getAdminProfile).then((res) => {
      console.log(res.data.data);

      this.setState(
        {
          profileData: res.data.data,
        },
        () => {
          console.log(this.state.profileData);
        }
      );
    });
    // }
  };

  submit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();

    if (init() === "success") {
      let submitLoad = {
        oldPassword: this.state.current_password,
        newPassword: this.state.new_password,
      };
      console.log(submitLoad);
      axios
        .post(Constants.postUrls.changePassword, submitLoad)
        .then((resp) => {
          console.log(resp);
          setTimeout(() => {
            this.setState({
              notification: true,
              loading: true,
              message: resp.data.message,
            });
          }, 2000);
          setTimeout(() => {
            this.setState({
              loading: false,
              redirect: true,
            });
          }, 4000);
        })
        .catch((err) => {
          if (err) {
            console.log(err);
            this.setState({
              loading: false,
              message: "Old Password Does Not Match",
              error: true,
            });
            setTimeout(() => {
              this.setState({
                error: false,
              });
            }, 3000);
          }
        });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleProfileModal = () => {
    this.setState({
      visibleProfile: true,
    });
  };

  handleClick = (event) => {
    this.setState({ showMenu: true, anchorEl: event.currentTarget });
  };
  handleClose = (event) => {
    this.setState({ showMenu: false, anchorEl: null });
  };
  applyTheme = () => {
    const theme = localStorage.getItem("theme");
    localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
    window.location.reload();
  };
  logout = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };
  render() {
    const state = this.state;
    return (
      <>
        <AppBar position="fixed" color="default" style={{ height: "75px" }}>
          <Toolbar style={{ height: "56px" }}>
            <Grid container>
              <Grid item xs={4}>
                <div
                  style={{
                    display: "flex",
                    width: "20rem",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {
                    //  this.state.profileData.vendor ?
                    //  this.state.profileData.vendor.brand_logo_url ?

                    <img
                      style={{
                        width: "30%",

                        marginRight: "2rem",
                        marginTop: "30px",
                        // borderRadius: "100%"
                      }}
                      src={LogoPR}
                    />
                    //   <img src={Constants.imgUrl+ this.state.profileData.vendor.brand_logo_url}
                    //     alt=" test logo"
                    //     style={{
                    //       width: "3rem",
                    //       height: "3rem",
                    //       display: "flex",
                    //       justifyContent: "center",
                    //       alignItems: "center",
                    //       marginRight:"1rem",
                    //     }}
                    //   />
                    //   :
                    // <img src="https://images.squarespace-cdn.com/content/v1/575a6067b654f9b902f452f4/1552683653140-0UUVQSSUEWVC73AWAEQG/300Logo.png"
                    //   alt=" test logo"
                    //   style={{
                    //     width: "3rem",
                    //     height: "3rem",
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     marginRight:"1rem",
                    //   }}
                    // />
                    // :
                    // <img src="https://images.squarespace-cdn.com/content/v1/575a6067b654f9b902f452f4/1552683653140-0UUVQSSUEWVC73AWAEQG/300Logo.png"
                    //   alt=" test logo"
                    //   style={{
                    //     width: "3rem",
                    //     height: "3rem",
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     marginRight:"1rem",
                    //   }}
                    // />
                  }

                  <Typography variant="h4" style={{ marginTop: "2rem" }}>
                    {/* {this.state.profileData.vendor ? 
                    this.state.profileData.vendor.brand_name ?
                    this.state.profileData.vendor.brand_name
                      : "Brand Name"
                      : "Brand Name"
                    } */}
                    PR GLOBAL
                  </Typography>
                </div>
              </Grid>

              {/* NOTIFICATION */}
              {this.state.notification ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  color={"s"}
                  autoHideDuration={6000}
                  message={this.state.message}
                ></Snackbar>
              ) : null}
              {/* NOTIFICATION */}
              <Grid item xs={8}>
                <Grid
                  container
                  alignItems="baseline"
                  style={{ marginTop: "40px" }}
                >
                  {/* <Typography variant="h6" style={{ marginRight: 25, }} > */}
                  {/* {this.state.profileData.vendor ? 
                    this.state.profileData.vendor.brand_name ?
                    this.state.profileData.vendor.brand_name
                      : "Brand Name"
                      : "Brand Name"
                    } */}

                  {/* CPU USAGE :     {this.state.cpu} */}

                  {/* </Typography> */}

                  {/* <Typography variant="h6" > */}
                  {/* {this.state.profileData.vendor ? 
                    this.state.profileData.vendor.brand_name ?
                    this.state.profileData.vendor.brand_name
                      : "Brand Name"
                      : "Brand Name"
                    } */}
                  {/* RAM LEFT :     {this.state.memory} */}

                  {/* </Typography> */}

                  <Grid
                    container
                    justify="flex-end"
                    alignItems="baseline"
                    style={{ marginTop: "5px" }}
                  >
                    <Grid
                      container
                      alignItems="baseline"
                      // style={{ marginTop: "40px" }}
                    >
                      {/* <Typography variant="h6" style={{ marginRight: 25 }}>
                    CPU USAGE : {this.state.cpu}
                  </Typography>

                  <Typography variant="h6">
                    RAM LEFT :{this.state.memory}
                  </Typography> */}

                      <Grid
                        container
                        justify="flex-end"
                        alignItems="baseline"
                        style={{ marginTop: "-10px" }}
                      >
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={this.handleClick}
                          endIcon={<ArrowDropDown />}
                        >
                          {this.state.profileData.name}
                        </Button>
                        <Menu
                          anchorEl={this.state.anchorEl}
                          id="simple-menu"
                          keepMounted
                          open={this.state.showMenu}
                          onClose={this.handleClose}
                        >
                          <MenuItem onClick={this.handleClose}>
                            <Button
                              onClick={this.handleProfileModal}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography
                                children={
                                  <p style={{ fontSize: 12 }}>Profile</p>
                                }
                              />
                              <PersonAddOutlined
                                color="action"
                                size={"small"}
                              />
                            </Button>
                          </MenuItem>

                          <MenuItem onClick={this.handleClose}>
                            {" "}
                            <Button
                              onClick={this.applyTheme}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                // marginRight: "2rem",
                              }}
                            >
                              {state.theme === "dark" ? (
                                <>
                                  <Typography
                                    children={
                                      <p style={{ fontSize: 12 }}>Dark Mode</p>
                                    }
                                  />
                                  <WbSunnyTwoTone color="action" />
                                </>
                              ) : (
                                <>
                                  <Typography
                                    children={
                                      <p style={{ fontSize: 12 }}>Light Mode</p>
                                    }
                                  />
                                  <SettingsBrightness color="action" />
                                </>
                              )}
                            </Button>
                          </MenuItem>
                          <MenuItem onClick={this.handleClose}>
                            <Button
                              onClick={this.handleModal}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography
                                children={
                                  <p style={{ fontSize: 12 }}>
                                    Change Password
                                  </p>
                                }
                              />
                              <LockOpenIcon color="action" size={"small"} />
                            </Button>
                          </MenuItem>
                          <MenuItem onClick={this.handleClose}>
                            <Button
                              onClick={this.logout}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography
                                children={
                                  <p style={{ fontSize: 12 }}>Logout</p>
                                }
                              />
                              <LockRounded color="action" size={"small"} />
                            </Button>
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Modal
          visible={this.state.visibleProfile}
          width="450"
          height="350"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visibleProfile: false })}
          style={{ position: "absolute" }}
        >
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div style={{ padding: "10px" }}>
                    <form className="loginFormWrapper">
                      <img
                        src={profile}
                        alt="img"
                        width="100px"
                        height="100px"
                        className="profileImg"
                      />
                      <div className="nameInput">
                        {this.state.profileData.name}
                      </div>
                      <div className="otherInputs">
                        {this.state.profileData.email}
                      </div>
                      <div className="otherInputs">
                        {this.state.profileData.mobile_no}
                      </div>
                      {/* <input 
                        type="text"
                        fullWidth
                        name="name"
                        value={this.state.profileData.name}
                        className="nameInput"
                      /> */}
                      {/* <TextField
                        style={{ margin: "1rem 0" }}
                        id="standard-basic"
                        // label="Name"
                        variant="standard"
                        fullWidth
                        name="name"
                        value={this.state.profileData.name}
                        onChange={this.handleInput}
                        required
                        autoComplete="off"
                        disabled
                      />
                      <TextField
                        style={{ marginBottom: "1rem" }}
                        id="standard-basic"
                        // label="Email"
                        variant="standard"
                        fullWidth
                        name="email"
                        value={this.state.profileData.email}
                        type="email"
                        onChange={this.handleInput}
                        required
                        autoComplete="off"
                        disabled
                      />
                      <TextField
                        style={{ marginBottom: "1rem" }}
                        id="standard-basic"
                        // label="Mobile Number"
                        variant="standard"
                        fullWidth
                        name="mobile_no"
                        value={this.state.profileData.mobile_no}
                        type="number"
                        onChange={this.handleInput}
                        required
                        autoComplete="off"
                        disabled
                      /> */}

                      {/* <Button
                        color="primary"
                        style={{ alignSelf: "flex-start" }}
                        variant="contained"
                        // size="large"
                        type="submit"
                      >
                        {this.state.loading ? (
                          "changing password "
                        ) : this.state.error ? (
                          <span style={{ color: "#BD0600" }}>
                            Something Went Wrong !!!
                          </span>
                        ) : (
                          " Submit"
                        )}
                      </Button> */}
                      <Button
                        variant="contained"
                        color="secondary"
                        className="closeBtn"
                        onClick={() => {
                          this.setState({ visibleProfile: false });
                        }}
                      >
                        Close
                      </Button>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal>

        <Modal
          visible={this.state.visible}
          width="600"
          height="237"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div className="modal" style={{ padding: "10px" }}>
                    <form
                      className="loginFormWrapperPass"
                      onSubmit={this.submit}
                    >
                      <input
                        type="password"
                        placeholder="Current Password"
                        name="current_password"
                        required
                        onChange={this.handleInput}
                        className="currentPass"
                      />

                      <input
                        type="password"
                        placeholder="New Password"
                        name="new_password"
                        required
                        onChange={this.handleInput}
                        className="currentPass"
                      />

                      <Button
                        color="primary"
                        // style={{ alignSelf: "flex-start" }}
                        className="submitChangePass"
                        variant="contained"
                        // size="large"
                        type="submit"
                      >
                        {this.state.loading ? (
                          "changing password "
                        ) : this.state.error ? (
                          <span style={{ color: "#BD0600" }}>
                            Something Went Wrong !!!
                          </span>
                        ) : (
                          " Submit"
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        // style={{ margin: "20px", marginLeft: "4px" }}
                        className="cancelChangePass"
                        onClick={() => {
                          this.setState({ visible: false });
                        }}
                      >
                        Cancel
                      </Button>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}
